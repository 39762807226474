import React, {Fragment, useEffect, useState}  from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import firebaseHelper from '../../../../Helpers/Firebase';

import Header from '../../../../Components/SideNav';
import Loader from '../../../../Components/Loader';



const Unidades = props => {
    const [state, setState] = useState([]);
    const { id } = useParams();

    useFirestoreConnect([
        {
          collection: 'unidades',
          where: [
            ['curso_id', '==', id ]
        ],
        orderBy:"posicion"
        }
    ]);

    const unidades = useSelector(
        ({ firestore: { data } }) => data.unidades
    );

    useEffect(() => {
        let unidadesArray = [];

        if (unidades !== undefined) {
            if (unidades !== null) {
                Object.entries(unidades).map(unidad => {
                    return unidadesArray.push({
                        ...unidad[1],
                        id: unidad[0]
                    });
                });
            }

            setState(unidadesArray);
        }
    }, [unidades]);

    const eliminar = (id) => {
        const { firestore } = props;

        firebaseHelper.eliminar(id, 'unidades', firestore);
    }

    return (
        <div>
            <Fragment>
                <Header mensaje="UNIDADES" />

                <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                    <Link to={`/admin/nueva-unidad/${id}`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR UNIDAD</Link>
                </div>

                <div className="container" style={{padding: '0 0 100px 0'}}>
                    {unidades === undefined ? (
                        <Loader />
                    ) : (
                        state.length === '0' ? (
                            <h2>Sin unidades</h2>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {state.map(unidad => (
                                        <tr key={unidad.id}>
                                            <td>{unidad.nombre}</td>
                                            <td>
                                                <a style={{marginLeft:'14%'}} href={`/admin/videos-unidad/${unidad.id}`}><i class="material-icons" style={{color:'#813A76'}}>remove_red_eye</i></a>
                                                <a style={{marginLeft:'14%'}} href={`/admin/editar-unidad/${unidad.id}/${id}`}><i class="material-icons" style={{color:'#813A76 '}}>create</i></a>
                                                <div onClick={ () => eliminar(unidad.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76 '}}>delete</i></div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    )}
                </div>
                
            </Fragment>
        </div>
    );
};

export default firestoreConnect() (Unidades);