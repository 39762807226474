// NOTE: hay dos tipos de envios
// NOTE: true => guias prepagadas estandares nacionales
// NOTE: false => guias prepagadaas dia siguiente nacionales
import React, {Fragment, useState, useEffect}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import firebaseHelper from '../../../Helpers/Firebase';

import Loader from '../../../Components/Loader';

const Envios = props => {
    const history = useNavigate();
    const [state, setState] = useState({
        estandar: ""
    });

    useEffect(() => {
        if (props.envios !== undefined) {
            setState({
                estandar: props.envios.estandar
            });
        }
    },[props]);

    const cambiarPrecioEstandar = e => {
        // separamos los valores dentro del classname
        var target = e.target.className.split(":");

        // el primer valor es el de el precio segun la cantidad
        var indexCantidad = target[0];

        // el segundo valor es el peso que corresponde el precio editado
        var indexEnvios = target[1];

        // hacemos una copia del state
        var estandarCopia = [...state.estandar];

        // hacemos una copia del json del peso
        var estandarPesoCopia = {...state.estandar[indexEnvios]}

        // hacemos una copia del array del prcio segun el peso
        var estandarCantidadCopia = [...state.estandar[indexEnvios].cantidad];

        // asignamos el valor que se cambio en la posicion del array de los precios
        estandarCantidadCopia[indexCantidad] = parseInt(e.target.value);

        // igualamos el array de los precios a la cantidad del json que se cambio
        estandarPesoCopia.cantidad = estandarCantidadCopia;

        // se actualiza el valo completo de la copia del state
        estandarCopia[indexEnvios] = estandarPesoCopia;

        // actualizamos el state
        setState({
            estandar: estandarCopia
        });
    }
    
    const actualizar = e => {
        e.preventDefault();

        //extraer valores del state
        var editar = {estandar: state.estandar};
    
        //extraer firestor de props
        const { firestore } = props;

        //actualizacion en la base de datos
        firebaseHelper.editar(props.envios.id, 'envios', firestore, editar, '/admin/envios-costos', history);
    }

    return (
        <Fragment>
            <Header mensaje="ENVIOS" />
            <div className="container" style={{marginBottom:"100px"}}>
                <h3>GUIAS PREPAGADAS ESTANDAR NACIONALES</h3>
                {!props.envios ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>PESO</th>
                                    <th>1 A 9</th>
                                    <th>10 A 19</th>
                                    <th>20 A 49</th>
                                    <th>50 A 100</th>
                                    <th>101 A 250</th>
                                    <th>251 A 500</th>
                                    <th>501 A 1000</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.envios.estandar.map((envio, indexEnvios) => (
                                    <tr>
                                        <th>
                                            {envio.peso}
                                        </th>
                                        {envio.cantidad.map((cantidad, indexCantidad) => (
                                            <th>
                                                <input type="number"  className={indexCantidad + ":" + indexEnvios} style={{textAlign:"center"}} onChange={cambiarPrecioEstandar} defaultValue={cantidad} />
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <button className='btn' style={{background:'#813A76'}} onClick={actualizar} type="submit">Actualizar</button>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'envios',
            doc: "z5IKES1zaNCXZlJyuIng"
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        envios: ordered.envios && ordered.envios[0]
    }))
)(Envios);
