import React, { Fragment } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Header from "../../../../Components/SideNav";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import firebaseHelper from "../../../../Helpers/Firebase";

import Loader from "../../../../Components/Loader";

const Promociones = (props) => {
  const eliminar = (id) => {
    const { firestore } = props;

    firebaseHelper.eliminar(id, "promocionesCursos", firestore);
  };

  return (
    <Fragment>
      <Header mensaje="PROMOCIONES CURSOS" />
      <div
        className="btn-outline-light center"
        style={{ padding: "30px 0 30px 0" }}
      >
        <Link
          to={`/admin/nueva-promocion-curso`}
          id="font"
          class="btn"
          style={{ padding: "0 50px 0 50px", background: "#813A76" }}
        >
          AGREGAR PROMOCION A CURSO
        </Link>
      </div>
      <div className="container">
        {!props.promocionesCursos ? (
          <Loader />
        ) : (
          <Table striped bordered hover style={{ marginBottom: "50px" }}>
            <thead>
              <tr>
                <th>Codigo Promocion</th>
                <th>Curso</th>
                <th>Descuento</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody id="font">
              {props.promocionesCursos.map((dato) => (
                <tr key={dato.id}>
                  <td>{dato.codigo}</td>
                  <td>{dato.curso}</td>
                  <td>{dato.descuento}</td>
                  <td>
                    <a
                      href="#!"
                      onClick={() => eliminar(dato.id)}
                      style={{ marginLeft: "14%" }}
                    >
                      <i class="material-icons" style={{ color: "#813A76" }}>
                        delete
                      </i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Fragment>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "promocionesCursos",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    promocionesCursos: ordered.promocionesCursos,
  }))
)(Promociones);
