import React, {Fragment, useEffect, useState}  from 'react';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';
import firebaseHelper from '../../../Helpers/Firebase';


const Productos = props => {
    const { id } = useParams();
    const [state, setState] = useState([]);

    useFirestoreConnect([
        {
          collection: 'producto',
          where: [
                ['categoria', '==', id ]
            ]
        }
    ]);

    const productos = useSelector(
        ({ firestore: { data } }) => data.producto
    );

    useEffect(() => {
        let productoArray = [];
        sessionStorage.categoria = id;

        if (productos !== undefined && productos !== null) {
            Object.entries(productos).map(producto => {
                return productoArray.push({
                    ...producto[1],
                    id: producto[0]
                });
            });

            setState(productoArray);
        }
    }, [productos, id]);

    const eliminar = (id, urlImagen) => {
        const { firestore } = props;
        const { firebase } = props;

        firebaseHelper.eliminarImagen(id, 'producto', firestore, firebase, urlImagen);
    }

    return (
        <Fragment>
            <Header mensaje="PRODUCTOS" />
            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={`/admin/nuevo-producto/${id}`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR PRODUCTO</Link>
                <Link to={'/admin/categorias'} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
            </div>

            {productos === undefined ? (
                <Loader />
            ) : (
                <div className="container">
                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Menudeo</th>
                                <th>Mayoreo</th>
                                <th>Cantidad</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody id="font">
                            {state.map(producto => (
                                <tr key={producto.id}>
                                    <td>{producto.nombre}</td>
                                    <td>$ {producto.precio}</td>
                                    <td>$ {producto.precioMayoreo}</td>
                                    <td>{producto.cantidad}</td>
                                    <td>
                                        <Link style={{marginLeft:'14%'}} to={`/admin/editar-producto/${producto.id}/${id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></Link>
                                        <a href="#!" onClick={ () => eliminar(producto.id, producto.urlImagen, producto.urlImagen135, producto.urlImagen50)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76'}}>delete</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </Fragment>
    );
};

export default firestoreConnect() (Productos);
