import React, {Fragment, useState, useEffect}  from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import firebaseHelper from '../../../Helpers/Firebase';


const EditarPregunta = props => {
    const { id } = useParams();
    const { idCurso } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        pregunta: '',
        respuesta_1: '',
        respuesta_2: '',
        respuesta_3: '',
        respuesta_correcta: ''
    });

    useFirestoreConnect([
        {
          collection: 'preguntas',
          doc: id
        }
    ]);

    const pregunta = useSelector(
        ({ firestore: { data } }) => data.preguntas && data.preguntas[id]
    );

    useEffect(() => {
        if (pregunta !== undefined) {
            setState({
                pregunta: pregunta.pregunta,
                respuesta_1: pregunta.respuesta_1,
                respuesta_2: pregunta.respuesta_2,
                respuesta_3: pregunta.respuesta_3,
                respuesta_correcta: pregunta.respuesta_correcta
            });
        }
    }, [pregunta]);

    const editar = e => {
        e.preventDefault();

        //extraer valores del state
        var editar = {...state};
    
        //extraer firestor de props
        const { firestore } = props;

        //actualizacion en la base de datos
        firebaseHelper.editar(id, 'preguntas', firestore, editar, '/admin/preguntas/' + idCurso, history);

    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR PREGUNTA" />
            <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                <form onSubmit={editar}>
                    <div style={{margin:'0 15%'}}>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.pregunta} name="pregunta" id="pregunta" type="text" class="validate" />
                            <label class="active" for="pregunta">Pregunta</label>
                        </div>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.respuesta_1} name="respuesta_1" id="respuesta_1" type="text" class="validate" />
                            <label for="respuesta_1" class="active">Respuesta 1</label>
                        </div>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.respuesta_2} name="respuesta_2" id="respuesta_2" type="text" class="validate" />
                            <label for="respuesta_2" class="active">Respuesta 2</label>
                        </div>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.respuesta_3} name="respuesta_3" id="respuesta_3" type="text" class="validate" />
                            <label for="respuesta_3" class="active">Respuesta 3</label>
                        </div>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.respuesta_correcta} name="respuesta_correcta" id="respuesta_correcta" type="text" class="validate" />
                            <label for="respuesta_correcta" class="active">Respuesta Correcta</label>
                        </div>
                    </div>
                    <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>EDITAR</button>
                    <Link to={`/admin/preguntas/${idCurso}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
                </form>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarPregunta);