import React, {Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import firebaseHelper from '../../../Helpers/Firebase';
import Loader from '../../../Components/Loader';


const Servicios = props => {
    const eliminar = (id, urlImagen) => {
        const { firestore } = props;
        const { firebase } = props;

        firebaseHelper.eliminarImagen(id, 'servicios', firestore, firebase, urlImagen);
        
    }

    return (
        <Fragment>
            <Header mensaje="SERVICIOS" />
            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={`/admin/nuevo-servicio`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR SERVICIO</Link>
            </div>
            <div className="container">
                {!props.servicios ? (
                    <Loader />
                ) : (
                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Descripcion</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody id="font">
                            {props.servicios.map(servicio => (
                                <tr key={servicio.id}>
                                    <td>{servicio.nombre}</td>
                                    <td>{servicio.descripcion}</td>
                                    <td>
                                        <a style={{marginLeft:'14%'}} href={`/admin/editar-servicio/${servicio.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></a>
                                        <a href="#!" onClick={ () => eliminar(servicio.id, servicio.urlImagen, servicio.urlImagen135, servicio.urlImagen50)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76'}}>delete</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'servicios'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios
    }))
)(Servicios);