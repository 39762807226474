import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Table } from "react-bootstrap";
import Header from "../../../Components/SideNav";

import Loader from "../../../Components/Loader";

const EnviosEnviado = (props) => {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    <Fragment>
      <Header mensaje="ENVIADOS" />

      <div className="container">
        {!props.compras ? (
          <Loader />
        ) : props.compras.length === 0 ? (
          <div className="center">
            <h2 id="font">No hay paquetes enviados</h2>
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Telefono</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody id="font">
              {props.compras.map((compra) => (
                <tr key={compra.id}>
                  <td style={{ fontSize: "20px" }}>
                    {compra.datos_envio.nombre}
                  </td>
                  <td style={{ fontSize: "20px" }}>
                    {compra.datos_contacto.correo}
                  </td>
                  <td style={{ fontSize: "20px" }}>
                    {compra.datos_contacto.telefono}
                  </td>
                  <td style={{ fontSize: "20px" }}>
                    {new Date(compra.fecha.seconds * 1000).getDate() +
                      " de " +
                      meses[new Date(compra.fecha.seconds * 1000).getMonth()] +
                      " del " +
                      new Date(compra.fecha.seconds * 1000).getFullYear()}
                  </td>
                  <td>
                    <a
                      style={{ marginLeft: "14%" }}
                      href={`/admin/estado-envio/${compra.id}`}
                    >
                      <i class="material-icons" style={{ color: "#813A76 " }}>
                        remove_red_eye
                      </i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Fragment>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "compras",
      where: [["estado_envio", "==", 2]],
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    compras: ordered.compras,
  }))
)(EnviosEnviado);
