import React, {Fragment, Component}  from 'react';
import ReactDOM from 'react-dom';
//import { firebaseConnect } from 'react-redux-firebase';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Header from '../../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import restAPI from '../../../../Helpers/RestAPI';
import { Table } from 'react-bootstrap';

//import PropTypes from 'prop-types';

class Videos extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            file1: '',
            picture: null,
            nombre: '',
            prueba: '',
            videos: [],
            uploadValue:"0%",
            idPage: ""
        }
    }


    componentDidMount = () => {
        this.setState({
            idPage: window.location.pathname.split("/")[3]
        })
        console.log(window.location.pathname.split("/")[3]);
    }

    handelUpload = e => {
        e.preventDefault();

        this.setState({
            file1: e.target.files[0]
        });
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        console.log("entro");
        console.log(this.props);
        if (this.state.prueba === '' && this.props.unidades) {
            this.setState({
                prueba: '1',
                nombre: this.props.unidades.nombre,
                videos: this.props.unidades.videos
            });
        }
    }

    subirDB = async file => {
        const { firestore } = this.props;
        const { firebase } = this.props;

        if (file === '1') {
            if ((this.state.file1 === '') || (this.state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                var file = this.state.file1;
                console.log('subir');
                const storageRef = firebase.storage().ref(`videos/${this.state.nombre}`)
                const task = storageRef.put(file)
                task.on('state_changed', (snapshot) => {
                    let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    this.setState({
                        uploadValue: percentage + "%"
                    })
                }, (error) => {
                    console.error(error.message)
                }, () => {
                    // Upload complete
                    task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        this.setState({
                            picture: downloadURL
                        }, () => {
                            console.log("subido");
                            var nuevoVideo = {
                                nombre: this.state.nombre,
                                video: this.state.picture
                            }
                            var videos = [...this.state.videos];
                            videos.push(nuevoVideo);

                            firestore.update({
                                collection: 'unidades',
                                doc: this.props.unidades.id
                            }, { videos } ).then(() => this.editado());
                            
                            console.log(nuevoVideo);
                        })
                    });

                })
            }
        }
    }

    editado = () => {
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        );

        window.location.reload();
    }

    eliminar = (url, position) => {
        //extraer firestor de props
        var { firebase } = this.props;
        const { firestore } = this.props;
        const { history } = this.props;


        var storage;
        var storageRefEliminar;

        storage = firebase.storage();
        storageRefEliminar = storage.refFromURL(url);
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let videos = [...this.props.unidades.videos];
                videos.splice(position, 1);
                storageRefEliminar.delete().then(() => {
                    //actualizacion en la base de datos
                    firestore.update({
                        collection: "unidades",
                        doc: this.props.unidades.id
                    }, {videos} ).then(() => {
                        Swal.fire({
                            title: 'Eliminación exitosa',
                            text:'El campo fue eliminado exitosamente',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon:'success'
                        })
                        history.go()
                    });
                }).catch(error => {
                    //console.log("error => ", error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al aliminar archivo 1',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    render(){
        if (!this.props.unidades) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje={"VIDEOS EN " + this.props.unidades.nombre} />
                    <div className="container center-align" style={{marginTop:'50px'}}>
                        <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>VIDEOS EN LA UNIDAD</h2>
                        <div class="progress">
                            <div class="determinate" style={{width:this.state.uploadValue}}></div>
                        </div>
                        <div className="row">
                            <div className='col s4'>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                    <label for="nombre">Nombre</label>
                                </div>
                                <div className="col s12">    
                                    <div className="file-field input-field">
                                        <div className="btn">
                                            <span>File</span>
                                            <input onChange={this.handelUpload} name="file" type="file"/>
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" placeholder="Seleccione un video o arrastre un video"/>
                                        </div>
                                    </div>
                                    <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                                        <div id="font" class="btn" onClick={() => this.subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR</div>
                                        <a href="/admin/cursos" id="font" class="btn" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#813A76'}}>REGRESAR</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col s8">
                                <video class="responsive-video" controls>
                                    <source id="imgSalida" width="100%" src="" type="video/mp4" />
                                </video>
                            </div>
                        </div>

                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Video</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {this.props.unidades.videos.map((dato, index) => (
                                    <tr key={dato.id}>
                                        <td>
                                            <p>{dato.nombre}</p>
                                        </td>
                                        <td>
                                            <video class="responsive-video" style={{width:"150px"}} controls>
                                                <source id="imgSalida" src={dato.video} type="video/mp4" />
                                            </video>
                                        </td>
                                        <td>
                                            <button style={{border:"none", background:"#fff"}} onClick={() => {
                                                this.eliminar(dato.video, index)
                                            }}>
                                                <i class="material-icons" style={{color:'#813A76 '}}>delete</i>
                                            </button>
                                            {!dato.descripcion ? (
                                                <a href={`/admin/nueva-descripcion/${this.state.idPage}/${index}`}>
                                                    <i class="material-icons" style={{color:'#813A76 '}}>add_box</i>
                                                </a>
                                            ) : (
                                                <a href={`/admin/editar-descripcion/${this.state.idPage}/${index}`}>
                                                    <i class="material-icons" style={{color:'#813A76 '}}>create</i>
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>
                </Fragment>
            );
        }
    }
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'unidades',
            doc: window.location.pathname.split("/")[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        unidades: ordered.unidades && ordered.unidades[0]
    }))
)(Videos);