import React, {Fragment, useState, useEffect}  from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';


const EditarCurso = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        titulo: ''
    });
    const [descripcion, setDescripcion] = useState("");
    const [requisitos, setRequisitos] = useState("");
    const [descripcionSecundaria, setDescripcionSecundaria] = useState("");

    useFirestoreConnect([
        {
          collection: 'cursos',
          doc: id
        }
    ]);

    const cursos = useSelector(
        ({ firestore: { data } }) => data.cursos && data.cursos[id]
    );

    useEffect(() => {
        if (cursos) {
            setState({
                titulo: cursos.titulo,
                titulo_secundario: cursos.titulo_secundario,
                titulo_exterior: cursos.titulo_exterior,
                descripcion: cursos.descripcion,
                descripcion_secundaria: cursos.descripcion_secundaria,
                requisitos: cursos.requisitos,
                precio: cursos.precio,
                online: cursos.online,
                alumnos: cursos.alumnos,
                certificado: cursos.certificado,
                horas: cursos.horas
            });
        }
    }, [cursos]);

    const editado = e => {
        e.preventDefault();

        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                //redireccion
                editar();
            } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    const editar = () => {
        var cursos;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        cursos = {
            ...state,
            descripcion,
            requisitos,
            descripcion_secundaria: descripcionSecundaria
        }
        console.log({cursos});

        //actualizacion en la base de datos
        firestore.update({
            collection: 'cursos',
            doc: id
        }, cursos ).then(() => {
            Swal.fire({
                title: 'Modificacion exitosa',
                text:'El campo fue modificado exitosamente',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon:'success'
            })
            history(`/admin/cursos`)
        });

    }

    //extrae los valores del input y los coloca en el state
    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerDescripcion = (e, editor) => {
        setDescripcion(editor.getData());
    }

    const descripcion_secundaria = (e, editor) => {
        setDescripcionSecundaria(editor.getData());

    }

    const leerRequisitos = (e, editor) => {
        setRequisitos(editor.getData());
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR CURSO ONLINE" />
            <div className="container center-align" style={{marginTop:'2%'}}>
                {!cursos ? (
                    <Loader />
                ) : (
                    <form onSubmit={editado}>  
                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.titulo} name="titulo" id="titulo" type="text" class="validate" />
                            <label className='active' for="titulo" id="font-text2">Titulo</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.titulo_secundario} name="titulo_secundario" id="titulo_secundario" type="text" class="validate" />
                            <label className='active' for="titulo_secundario" id="font-text2">Titulo Secundario</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.titulo_exterior} name="titulo_exterior" id="titulo_exterior" type="text" class="validate" />
                            <label className='active' for="titulo_exterior" id="font-text2">Titulo Exterior</label>
                        </div>

                        <div>
                            <h5>Descripcion</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                data= {String(state.descripcion)}
                                onChange={leerDescripcion}
                            />
                        </div>

                        <div>
                            <h5>Descripcion Secundaria</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                data= {String(state.descripcion_secundaria)}
                                onChange={descripcion_secundaria}
                            />
                        </div>

                        <div>
                            <h5>Requisitos</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                data= {String(state.requisitos)}
                                onChange={leerRequisitos}
                            />
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.precio} name="precio" id="precio" type="text" class="validate" />
                            <label className='active' for="precio" id="font-text2">Precio</label>
                        </div>

                        <div>
                            <h5>Incluye</h5>
                            <div class="input-field">
                                <input onChange={leerDato} defaultValue={state.kit} name="kit" id="kit" type="text" class="validate" />
                                <label className='active' for="kit" id="font-text2">Kit</label>
                            </div>
                            <div class="input-field">
                                <input required onChange={leerDato} defaultValue={state.online} name="online" id="online" type="text" class="validate" />
                                <label className='active' for="online" id="font-text2">Online</label>
                            </div>
                            <div class="input-field">
                                <input required onChange={leerDato} defaultValue={state.horas} name="horas" id="horas" type="text" class="validate" />
                                <label className='active' for="horas" id="font-text2">Horas</label>
                            </div>
                            <div class="input-field">
                                <input required onChange={leerDato} defaultValue={state.alumnos} name="alumnos" id="alumnos" type="text" class="validate" />
                                <label className='active' for="alumnos" id="font-text2">Alumnos</label>
                            </div>
                            <div class="input-field">
                                <input required onChange={leerDato} defaultValue={state.certificado} name="certificado" id="certificado" type="text" class="validate" />
                                <label className='active' for="certificado" id="font-text2">Certificado</label>
                            </div>
                        </div>
                                    
                        <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                            <button id="font" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>EDITAR</button>
                            <Link to={`/admin/cursos`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
                        </div>
                    </form>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarCurso);
