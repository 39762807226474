import React, {Fragment, useState}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Table } from 'react-bootstrap';


const VentasCursos = props => {
    const [state, setState] = useState({
        rango: [
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), 
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)
        ], 
        ventas: "",
        total: ""
    });

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const buscarUsuarios = async (ventas, indexBusqueda, tamanioVentas) => {
        const { firestore } = props;
        let nombre, copiaVentas = ventas, copiaIndex = indexBusqueda;

        if (tamanioVentas > indexBusqueda) {
            let res = await firebaseHelper.buscarId(ventas[indexBusqueda].id_comprador, "user", firestore);
            if (res === 1) {
                nombre = "Cuenta eliminada"
            } else {
                nombre = res.nombre
            }

            copiaVentas[indexBusqueda].comprador = nombre;
            copiaIndex = copiaIndex + 1;

            buscarUsuarios(copiaVentas, copiaIndex, tamanioVentas);
        } else {
            setState({
                ...state,
                ventas
            });
        }
    }

    const buscar = async () => {
        const { firestore } = props;

        let fecha_inicial = Math.ceil(state.rango[0].getTime() / 1000);
        let fecha_final = Math.ceil(state.rango[1].getTime() / 1000);

        setState({
            ...state,
            ventas: false
        });

        let res = await firebaseHelper.obtenerVentas(firestore, fecha_inicial ,fecha_final)

        if (res === 1) {
            setState({
                ...state,
                ventas: []
            });
        } else {
            let ventas = [];

            res.forEach(doc => {
                console.log(doc.data());
                ventas.push(doc.data());
            });

            console.log(ventas);
            buscarUsuarios(ventas, 0, ventas.length);
        }
    }

    return (
        <Fragment>
            <Header mensaje="VENTAS CURSOS" />

            <div className="container">
                <DateRangePickerComponent placeholder="Selecciona el rango de fechas"
                    name="rango"
                    onChange={leerDato}
                    startDate={new Date(state.rango[0])}
                    endDate={new Date(state.rango[1])}
                    minDays={2}
                    maxDays={31}
                    format="dd-MMM-yyyy"
                ></DateRangePickerComponent>
                <button id="font-button" class="btn" onClick={buscar} style={{padding:'0 50px 0 50px', margin:"50px", background:'#813A76'}}>BUSCAR</button>
            </div>

            <div className="container center" id="datos_ventas">
                {state.ventas === "" ? (
                    <h2>Selecciona un rango de fechas</h2>
                ) : (
                    !state.ventas ? (
                        <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                            <div class="preloader-wrapper big active">
                                <div class="spinner-layer spinner-red darken-4-only">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="gap-patch">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        state.ventas.length === 0 ? (
                            <h2>Sin ventas en el rango seleccionado</h2>
                        ) : (
                            <div>
                                <Table striped bordered hover style={{marginBottom:'50px'}}>
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Usuario</th>
                                            <th>Curso</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody id="font">
                                        {state.ventas.map(dato => (
                                            <tr key={dato.id_compra}>
                                                <td>{
                                                    new Date(dato.fecha.seconds * 1000).getDate() + "/" +
                                                    new Date(dato.fecha.seconds * 1000).getMonth() + "/" +
                                                    new Date(dato.fecha.seconds * 1000).getFullYear() + " " +
                                                    new Date(dato.fecha.seconds * 1000).getHours() + ":" + 
                                                    new Date(dato.fecha.seconds * 1000).getMinutes()
                                                }</td>
                                                <td>{dato.comprador}</td>
                                                <td>
                                                    {dato.porductos[0].nombre}
                                                </td>
                                                <td>
                                                    <a style={{marginLeft:'14%'}} target="blank" href={`/admin/venta/${dato.id_compra}`}><i class="far fa-eye" style={{color:'#813A76 '}}></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )
                    )
                )}
            </div>

            
        </Fragment>
    );
};

export default firestoreConnect() (VentasCursos);
