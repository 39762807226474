import React, {Fragment, useEffect, useState}  from 'react';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const InventarioProductos = () => {
    const [state, setState] = useState([]);
    const { id } = useParams();
    
    useFirestoreConnect([
        {
          collection: 'producto',
          where: ['categoria', '==', id]
        }
    ]);

    const productos = useSelector((state) => state.firestore.data.producto)
    // const productos = useSelector(
    //     ({ firestore: { data } }) => data.producto
    // )
    
    useEffect(() => {
        let productoArray = [];
        sessionStorage.categoria = id;

        if (productos) {
            Object.entries(productos).map(producto => {
                productoArray.push({
                    ...producto[1],
                    id: producto[0]
                })
            });

            setState(productoArray);
        }
    },[productos]);


    return (
        <Fragment>
            <Header mensaje="PRODUCTOS INVENTARIO" />
            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={'/admin/inventario'} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
            </div>
            <div className="container">
                <Table striped bordered hover style={{marginBottom:'50px'}}>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Precio Menudeo</th>
                            <th>Precio Mayoreo</th>
                            <th>Cantidad en Stock</th>
                            <th>Modificar</th>
                        </tr>
                    </thead>
                    <tbody id="font">
                        {!productos ? (
                            <Loader />
                        ) : (
                            state.map(producto => (
                                <tr key={producto.id}>
                                    <td>{producto.nombre}</td>
                                    <td>$ {producto.precio}</td>
                                    <td>$ {producto.precioMayoreo}</td>
                                    <td>{producto.cantidad}</td>
                                    <td>
                                        <Link style={{marginLeft:'14%'}} to={`/admin/inventario-editar/${producto.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
            
        </Fragment>
    );
};

export default InventarioProductos;