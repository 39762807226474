import React, {Fragment, useState}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../../Components/SideNav';
import { Link, useParams, useNavigate } from 'react-router-dom';
import firebaseHelper from '../../../../Helpers/Firebase';


const NuevaUnidad = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        videos: [],
        curso_id: id
    });

    const agregar = e => {
        e.preventDefault();

        //extraer valores del state
        const nuevo = {...state};
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        firebaseHelper.agregar('unidades', firestore, nuevo, `/admin/unidades/${id}`, history);
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="NUEVA UNIDAD" />
            <div id="font" className="container">    
                <div className="center" style={{padding: '30px 0 30px 0'}}>
                    <form onSubmit={agregar}>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                <label for="nombre">Nombre</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="posicion" id="posicion" type="text" class="validate" />
                                <label for="posicion">Posición</label>
                            </div>
                        </div>
                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR</button>
                        <Link to={`/admin/unidades/${id}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (NuevaUnidad);