import React, {Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Table } from 'react-bootstrap';


import Loader from '../../../Components/Loader';

const Cursos = props => {

    const eliminar = (id) => {
        const { firestore } = props;
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Eliminado con exito',
                    text:'El campo fue eliminado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                }
            )
            //eliminar curso
            firestore.delete({
                collection: 'cursos',
                doc: id
            })
            } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }
    
    return (
        <Fragment>
            <Header mensaje="CURSOS" />
            <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                <Link to={'/admin/nuevo-curso'} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR CURSO</Link>
            </div>

            <div className="container">
                <h3>Cursos online</h3>
                <div>
                    {!props.cursos_online ? (
                        <Loader />
                    ) : (
                        props.cursos_online.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay cursos registrados</h2>
                            </div>
                        ) : (
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Titulo</th>
                                        <th>Titulo Secundario</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {props.cursos_online.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.titulo}</td>
                                            <td>{dato.titulo_secundario}</td>
                                            <td>
                                                <Link style={{marginLeft:'20px'}} to={`/admin/unidades/${dato.id}`}><i class="material-icons" style={{color:'#813A76'}}>remove_red_eye</i></Link>
                                                <Link style={{marginLeft:'20px'}} to={`/admin/curso-editar/${dato.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></Link>
                                                <a href="#!" onClick={ () => eliminar(dato.id)}  style={{marginLeft:'20px'}}><i class="material-icons" style={{color:'#813A76'}}>delete</i></a>
                                                <a href={`/admin/preguntas/${dato.id}`}  style={{marginLeft:'20px'}}><i class="material-icons" style={{color:'#813A76'}}>question_answer</i></a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    )}
                </div>
                
                <h3>Cursos presenciales</h3>
                <div style={{marginBottom:"100px"}}>
                    {!props.cursos_presencial ? (
                        <Loader />
                    ) : (
                        props.cursos_presencial.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay cursos registrados</h2>
                            </div>
                        ) : (
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Titulo</th>
                                        <th>Duración</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {props.cursos_presencial.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.nombre}</td>
                                            <td>{dato.duracion}</td>
                                            <td>
                                                <Link style={{marginLeft:'20px'}} to={`/admin/curso-presencial-editar/${dato.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></Link>
                                                <Link style={{marginLeft:'20px'}} to={`/admin/curso-presencial-imagenes/${dato.id}`}><i class="material-icons" style={{color:'#813A76'}}>burst_mode</i></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default compose(
    firestoreConnect([ 
        { 
            collection: 'cursos',
            orderBy: "titulo_exterior",
            storeAs: "cursos_online"
        },
        { 
            collection: 'cursos',
            orderBy: "tipo",
            storeAs: "cursos_presencial"
        }
    ]),
    connect((state, props) => ({
        cursos_online : state.firestore.ordered.cursos_online,
        cursos_presencial: state.firestore.ordered.cursos_presencial
    }))
)(Cursos);