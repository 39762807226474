// NOTE: hay dos tipos de compras
// NOTE: true => guias prepagadas estandares nacionales
// NOTE: false => guias prepagadaas dia siguiente nacionales
import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useFirestoreConnect, firestoreConnect } from "react-redux-firebase";

import Header from "../../../Components/SideNav";

import Swal from "sweetalert2";
import conekta from "../../../Helpers/Conekta";
import { Table } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";

import firebaseHelper from "../../../Helpers/Firebase";

const EnvioCompra = (props) => {
  const { id } = useParams();
  const history = useNavigate();
  const [state, setState] = useState({});

  useFirestoreConnect([
    {
      collection: "compras",
      doc: id,
    },
  ]);

  const compras = useSelector(
    ({ firestore: { data } }) => data.compras && data.compras[id]
  );

  useEffect(() => {
    if (compras) {
      consulta();
    }
    // eslint-disable-next-line
  }, [compras]);

  const consulta = async () => {
    var res = await conekta.obtenerVenta(compras.id_compra);
    if (res.success) {
      setState({
        data: res.res,
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Error al obtener venta",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }
  };

  const actualizar = (e) => {
    e.preventDefault();

    //extraer valores del state
    var editar = { ...compras };
    if (compras.estado_envio === 0) {
      editar.estado_envio = 1;
    } else {
      editar.estado_envio = 2;
    }

    //extraer firestor de props
    const { firestore } = props;

    //actualizacion en la base de datos
    firebaseHelper.editar(
      id,
      "compras",
      firestore,
      editar,
      "/admin/envios-empaquetar",
      history
    );
  };

  return (
    <Fragment>
      <Header mensaje="INFORMACIÓN DE ENVIO" />
      <div className="container">
        {!state.data ? (
          <div
            className="container center-align"
            style={{ marginTop: "20%", marginBottom: "20%" }}
          >
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-red darken-4-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container" style={{ marginBottom: "100px" }}>
            <Link
              to={"/admin/envios-empaquetar"}
              id="font-button"
              class="btn"
              style={{
                padding: "0 50px 0 50px",
                margin: "50px 0px",
                background: "#813A76 ",
              }}
            >
              REGRESAR
            </Link>

            <div>
              <div style={{ border: "1px solid grey", borderBottom: "0px" }}>
                <p className="titulo_ventas">Resumen de la orden</p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">ESTADO</p>
                </div>
                <div className="div_ventas_2">
                  {state.data.payment_status !== undefined ? (
                    <p
                      style={{ color: "green", margin: "0px", padding: "15px" }}
                    >
                      Pagado
                    </p>
                  ) : (
                    <p style={{ color: "red", margin: "0px", padding: "15px" }}>
                      Declinado
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">FECHA DE CREACIÓN</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">
                    {new Date(state.data.created_at * 1000).getDate() +
                      "/" +
                      (new Date(state.data.created_at * 1000).getMonth() + 1) +
                      "/" +
                      new Date(state.data.created_at * 1000).getFullYear() +
                      " " +
                      new Date(state.data.created_at * 1000).getHours() +
                      ":" +
                      new Date(state.data.created_at * 1000).getMinutes()}
                  </p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div style={{ border: "1px solid grey", borderBottom: "0px" }}>
                <p className="titulo_ventas">Cliente</p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">NOMBRE</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">{state.data.customer_info.name}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_3">
                  <p className="parrafo_1">CORREO ELECTRÓNICO</p>
                </div>
                <div className="div_ventas_4">
                  <p className="parrafo_1">{state.data.customer_info.email}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">TELÉFONO</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">{state.data.customer_info.phone}</p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div style={{ border: "1px solid grey", borderBottom: "0px" }}>
                <p className="titulo_ventas">Artículos</p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_7">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ARTÍCULO</th>
                        <th>CANTIDAD</th>
                        <th>PRECIO UNITARIO</th>
                      </tr>
                    </thead>
                    <tbody id="font">
                      {state.data.line_items.data.map((dato) => (
                        <tr key={dato.id}>
                          <td>{dato.name}</td>
                          <td>{dato.quantity}</td>
                          <td>
                            $ {parseFloat(dato.unit_price / 100).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div style={{ border: "1px solid grey", borderBottom: "0px" }}>
                <p className="titulo_ventas">Datos de envio</p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">NOMBRE</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">{compras.datos_envio.nombre}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_3">
                  <p className="parrafo_1">APELLIDO</p>
                </div>
                <div className="div_ventas_4">
                  <p className="parrafo_1">{compras.datos_envio.apellido}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">PAIS</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">{compras.datos_envio.pais}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_3">
                  <p className="parrafo_1">ESTADO</p>
                </div>
                <div className="div_ventas_4">
                  <p className="parrafo_1">{compras.datos_envio.estado}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">CIUDAD</p>
                </div>
                <div className="div_ventas_2">
                  <p className="parrafo_1">{compras.datos_envio.ciudad}</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_3">
                  <p className="parrafo_1">CODIGO POSTAL</p>
                </div>
                <div className="div_ventas_4">
                  <p className="parrafo_1">
                    {compras.datos_envio.codigo_postal}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_3">
                  <p
                    className="parrafo_1"
                    style={{ borderTop: "solid 1px grey" }}
                  >
                    DIRECCION
                  </p>
                </div>
                <div className="div_ventas_4">
                  <p
                    className="parrafo_1"
                    style={{ borderTop: "solid 1px grey" }}
                  >
                    {compras.datos_envio.direccion}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="div_ventas_1">
                  <p className="parrafo_1">COLONIA</p>
                </div>
                <div className="div_ventas_2">
                  {compras.datos_envio.colonia ? (
                    <p className="parrafo_1">{compras.datos_envio.colonia}</p>
                  ) : (
                    <p className="parrafo_1">Sin colonia</p>
                  )}
                </div>
              </div>
            </div>

            {compras.estado_envio === 0 && (
              <p className="titulo_ventas" style={{ color: "red" }}>
                Compra por empaquetar
              </p>
            )}
            {compras.estado_envio === 1 && (
              <p className="titulo_ventas" style={{ color: "#d5d500" }}>
                Compra por enviar
              </p>
            )}
            {compras.estado_envio === 2 && (
              <p className="titulo_ventas" style={{ color: "green" }}>
                Compra enviada
              </p>
            )}

            <button
              className="btn"
              onClick={actualizar}
              style={{
                padding: "0 50px 0 50px",
                margin: "50px 0px",
                background: "#813A76 ",
              }}
            >
              PARA ENVIAR
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default firestoreConnect()(EnvioCompra);

// export default compose(
//     firestoreConnect(props => [
//         {
//             collection: 'compras',
//             doc: props.match.params.id
//         }
//     ]),
//     connect(({ firestore: { ordered }}, props ) => ({
//         compras: ordered.compras && ordered.compras[0]
//     }))
// )(Envios);
