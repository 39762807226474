import React, {Fragment, useState, useEffect}  from 'react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../../Components/SideNav';
import restAPI from '../../../../Helpers/RestAPI';



const NuevaImagen = props => {
    const { id, idCategoria } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        file1: '',
        picture: null,
        nombre: '',
        prueba: ''
    });

    useFirestoreConnect([
        {
          collection: 'producto',
          doc: id
        }
    ]);

    const producto = useSelector(
        ({ firestore: { data } }) => data.producto && data.producto[id]
    );

    useEffect(() => {
        if (producto !== undefined) {
            const productoCopia = {
                categoria: producto.categoria,
                urlImagen: producto.urlImagen,
            }
    
            setState({
                ...state,
                prueba: '1',
                nombre: producto.nombre,
                producto: productoCopia
            });
        }
        // eslint-disable-next-line
    }, [producto]);

    const handelUpload = e => {
        e.preventDefault();

        console.log(e.target.files);

        setState({
            ...state,
            file1: e.target.files[0]
        });

        precarga(e.target.files[0]);
    }

    const precarga = file => {
        var imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    const subirDB = async file => {
        const { firestore } = props;

        if (file === '1') {
            if ((state.file1 === '') || (state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                preloader();
                var file1 = state.file1;
                const api = await restAPI.upload({
                    data: file1,
                    name: state.nombre
                });

                console.log({api});
                if (api.success) {
                    state.producto.urlImagen = api.dataURL;

                    console.log(state.producto, {producto});

                    firestore.update({
                        collection: 'producto',
                        doc: id
                    }, state.producto ).then(() => editado());
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: api.type,
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                }
            }
        }
    }

    const editado = () => {
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        )
        history(`/admin/imagenes/${idCategoria}`)
    }

    const preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    return (
        <Fragment>
            <Header mensaje="imagen a editar" />
            <div className="container center-align" style={{marginTop:'50px'}}>
                <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>AGREGAR IMAGEN A PRODUCTO</h2>
                <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                    
                </div>
                <div className="row">
                    <div className="col s4">    
                        <div className="file-field input-field">
                            <div className="btn">
                                <span>File</span>
                                <input onChange={handelUpload} name="file" type="file"/>
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                            </div>
                        </div>
                        <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                            <div id="font" class="btn" onClick={() => subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR</div>
                            <Link to={`/admin/imagenes/${idCategoria}`} id="font" class="btn" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#813A76'}}>REGRESAR</Link>
                        </div>
                    </div>
                    <div className="col s8">
                        <img alt="imagen subida" id="imgSalida" width="100%" src=""></img>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (NuevaImagen);