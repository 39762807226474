import React, {Fragment, Component}  from 'react';
import ReactDOM from 'react-dom';
//import { firebaseConnect } from 'react-redux-firebase';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Header from '../../../../Components/SideNav';
import Swal from 'sweetalert2';
import restAPI from '../../../../Helpers/RestAPI';


class App extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            file1: '',
            picture: null,
            nombre: '',
            prueba: ''
        }
    }

    handelUpload = e => {
        e.preventDefault();

        this.setState({
            file1: e.target.files[0]
        }, () => {
            this.precarga();
        });
    }

    precarga = () => {
        var file = this.state.file1,
        imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        const servicios = {
            urlImagen: this.props.servicios.urlImagen,
        }
        if (this.state.prueba === '') {
            this.setState({
                prueba: '1',
                nombre: this.props.servicios.nombre,
                servicios
            });
        }
    }

    subirDB = async file => {
        const { firestore } = this.props;

        if (file === '1') {
            if ((this.state.file1 === '') || (this.state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                this.preloader();
                var file1 = this.state.file1;
                const api = await restAPI.upload({
                    data: file1,
                    name: this.state.nombre + "-interna"
                });
                if (api.success) {
                    this.state.servicios.urlInterna = api.dataURL;

                    firestore.update({
                        collection: 'servicios',
                        doc: this.props.servicios.id
                    }, this.state.servicios ).then(() => this.editado());
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: api.type,
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                }
            }
        }
    }

    editado = () => {
        const { history } = this.props;
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        )
        history.push(`/admin/imagenes-categorias`);
    }

    preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    render(){
        if (!this.props.servicios) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="imagen a editar interna" />
                    <div className="container center-align" style={{marginTop:'50px'}}>
                        <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>AGREGAR IMAGEN A SERVICIO</h2>
                        <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                            
                        </div>
                        <div className="row">
                            <div className="col s4">    
                                <div className="file-field input-field">
                                    <div className="btn">
                                        <span>File</span>
                                        <input onChange={this.handelUpload} name="file" type="file"/>
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                    </div>
                                </div>
                                <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                                    <div id="font" class="btn" onClick={() => this.subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR</div>
                                    <a href={`/admin/imagenes-categorias`} id="font" class="btn" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#813A76'}}>REGRESAR</a>
                                </div>
                            </div>
                            <div className="col s8">
                                <img alt="imagen subida" id="imgSalida" width="100%" src=""></img>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'servicios',
            doc: window.location.pathname.split("/")[4]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(App);