import React, {Fragment, useEffect, useState}  from 'react';

import Header from '../../../Components/SideNav';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import M from 'materialize-css';

import { firestoreConnect } from 'react-redux-firebase';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const NuevoProducto = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        especificacion: '',
        precio: '',
        cantidad: '',
        categoria: '',
        urlImagen: '',
        precioMayoreo: '',
        galeria: [],
        peso: 0
    });
    
    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);

        setState({
            ...state,
            categoria: sessionStorage.getItem('categoria')
        });
        // eslint-disable-next-line
    }, []);

    const agregar = e => {
        e.preventDefault();

        var producto;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        producto = {...state}
        
        firestore.add({ collection: 'producto' }, producto).then(() => agregado());

    }

    const agregado = () => {
        Swal.fire(
            'Inserción exitosa!',
            'Campo agregado correctamente',
            'success'
        )
        history(`/admin/productos/${id}`)
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const especificacion = (e, editor) => {
        setState({
            ...state,
            especificacion: editor.getData()
        })
    }

    return (
        <Fragment>
            <Header mensaje="NUEVO PRODUCTO" />
            <div id="font" className="container">    
                <div className="center" style={{padding: '30px 0 30px 0'}}>
                    <form onSubmit={agregar}>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                <label for="nombre">Nombre del Producto</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="marca" id="marca" type="text" class="validate" />
                                <label for="marca">Marca</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="precio" id="precio" type="number" step=".01" class="validate" />
                                <label for="precio">Precio Menudeo</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="precioMayoreo" id="precioMayoreo" type="number" step=".01" class="validate" />
                                <label for="precioMayoreo">Precio Mayoreo</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="cantidad" id="cantidad" type="number" class="validate" />
                                <label for="cantidad">Cantidad en Stock</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="peso" id="peso" type="number" class="validate" />
                                <label for="peso">Peso</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <h5>Espicificación</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={especificacion}
                            />
                        </div>

                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76', marginTop:'25px'}}>AGREGAR</button>
                        <Link to={`/admin/productos/${id}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76', marginTop:'25px'}}>REGRESAR</Link>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (NuevoProducto);