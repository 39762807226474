import React, {Fragment}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import firebaseHelper from '../../../Helpers/Firebase';
import Loader from '../../../Components/Loader';

const CodigosVisitas = props => {
    const eliminar = (id) => {
        const { firestore } = props;

        firebaseHelper.eliminar(id, 'codigosVisitas', firestore);
    }

    return (
        <Fragment>
            <Header mensaje="CODIGOS DE VISITAS" />
            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={`/admin/nuevo-codigo`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR CODIGO</Link>
            </div>
            <div className="container">
                {!props.codigosVisitas ? (
                    <Loader />
                ) : (
                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                        <thead>
                            <tr>
                                <th>Codigo Visita</th>
                                <th>Clienta</th>
                                <th>Fecha</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody id="font">
                            {props.codigosVisitas.map(dato => (
                                <tr key={dato.id}>
                                    <td>{dato.codigo}</td>
                                    <td>{dato.clienta}</td>
                                    <td>{dato.fecha}</td>
                                    <td>
                                        <a href="#!" onClick={ () => eliminar(dato.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76'}}>delete</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'codigosVisitas'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        codigosVisitas: ordered.codigosVisitas
    }))
)(CodigosVisitas);