import React, {Fragment, useState, useEffect}  from 'react';
import Header from '../../../Components/SideNav';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';
import Loader from '../../../Components/Loader';


const EditarServicios = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        descripcion: '',
        slogan: '',
        preguntas: '',
        opciones: '',
        urlImagen: '',
        urlInterna: '',
        galeria: []
    });

    useFirestoreConnect([
        {
          collection: 'servicios',
          doc: id
        }
    ])

    const servicio = useSelector(
        ({ firestore: { data } }) => data.servicios && data.servicios[id]
    )

    useEffect(() => {
        if (servicio !== undefined) {
            setState({
                nombre: servicio.nombre,
                descripcion: servicio.descripcion,
                slogan: servicio.slogan,
                preguntas: servicio.preguntas,
                opciones: servicio.opciones,
                urlImagen: servicio.urlImagen,
                urlInterna: servicio.urlInterna,
                galeria: servicio.galeria
            });
        }
    },[servicio]);

    const editar = e => {
        e.preventDefault();
        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Modificacion exitosa',
                    text:'El campo fue modificado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                })
                //redireccion
                editado()
            
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    const editado = () => {
        var servicios;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        servicios = {...state}

        //actualizacion en la base de datos
        firestore.update({
            collection: 'servicios',
            doc: id
        }, servicios ).then(() => {
            history(`/admin/servicios`)
        });
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const preguntas = (e, editor) => {
        setState({
            ...state,
            preguntas: editor.getData()
        })
    }

    const opciones = (e, editor) => {
        setState({
            ...state,
            opciones: editor.getData()
        })
    }

    const descripcion = (e, editor) => {
        setState({
            ...state,
            descripcion: editor.getData()
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR SERVICIO" />
            {!servicio ? (
                <Loader />
            ) : (
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={editar}>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={leerDato} defaultValue={servicio.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                    <label className='active' for="nombre">Nombre del Servicio</label>
                                </div>
                            </div>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input onChange={leerDato} defaultValue={servicio.slogan} name="slogan" id="slogan" type="text" class="validate" />
                                    <label className='active' for="slogan">Slogan</label>
                                </div>
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <h5>Descripcion</h5>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data= {String(servicio.descripcion)}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={descripcion}
                                />
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <h5>Opciones</h5>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data= {String(servicio.opciones)}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={opciones}
                                />
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <h5>Preguntas</h5>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data= {String(servicio.preguntas)}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={preguntas}
                                />
                            </div>

                            <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76', marginTop:'25px'}}>EDITAR</button>
                            <Link to={`/admin/servicios`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76', marginTop:'25px'}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default firestoreConnect() (EditarServicios);