import React, {Fragment, useEffect, useState}  from 'react';
import { Table } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { useParams, Link } from 'react-router-dom';
import Loader from '../../../../Components/Loader';
import Header from '../../../../Components/SideNav';


const Imagenes = () => {
    const { id } = useParams();
    const [state, setState] = useState([]);

    useFirestoreConnect([
        {
            collection: 'producto',
            where: [
                ['categoria', '==', id ]
            ]
        }
    ]);

    const productos = useSelector(
        ({ firestore: { data } }) => data.producto
    );

    useEffect(() => {
        let productoArray = [];
        sessionStorage.categoria = id;

        if (productos !== undefined) {
            Object.entries(productos).map(producto => {
                return productoArray.push({
                    ...producto[1],
                    id: producto[0]
                });
            });

            setState(productoArray);
        }
    },[productos]);

    return (
        <Fragment>
            <Header mensaje="IMAGENES" />

            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={`/admin/imagenes-categorias`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76', marginTop:'25px'}}>REGRESAR</Link>
            </div>

            <div className="container">

                <Table striped bordered hover style={{marginBottom:'50px'}}>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody id="font">
                        {!productos ? (
                            <Loader />
                        ) : (
                            state.map(producto => (
                                <tr key={producto.id}>
                                    <td>{producto.nombre}</td>
                                    <td>{producto.precio}</td>
                                    <td>{producto.cantidad}</td>
                                    <td>
                                        {producto.urlImagen === "" ? (
                                            <Link style={{marginLeft:'14%'}} to={`/admin/imagenes/nueva-imagenes/${id}/${producto.id}`}><i class="material-icons" style={{color:'#813A76'}}>add_circle</i></Link>
                                        ) : (
                                            <Link style={{marginLeft:'14%'}} to={`/admin/imagenes/editar-imagenes/${id}/${producto.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></Link>
                                        )}
                                        <Link style={{marginLeft:'14%'}} to={`/admin/galeria/${id}/${producto.id}`}><i  style={{color:'#813A76'}} class="far fa-images"></i></Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
            
        </Fragment>
    );
};

export default Imagenes;
// export default compose(
//     firestoreConnect(props => [ 
//         {
//             collection: 'producto',
//             storeAs: 'productos',
//             where: [
//                 ['categoria', '==', props.match.params.id ]
//             ]
//         }
//     ]),
//     connect(({ firestore: { ordered }}, props ) => ({
//         productos: ordered.productos
//     }))
// )(App);