import React, { Fragment, useState, useEffect } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Header from "../../../../Components/SideNav";
import { Link, useNavigate } from "react-router-dom";
import firebaseHelper from "../../../../Helpers/Firebase";
import M from "materialize-css";
import Loader from "../../../../Components/Loader";

const NuevaPromocion = (props) => {
  const history = useNavigate();
  const [state, setState] = useState({
    codigo: "",
    descuento: "",
    curso: "",
  });

  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems);
  }, [props]);

  const agregar = (e) => {
    e.preventDefault();

    //extraer valores del state
    const nuevo = { ...state };

    //extraer firestor de props
    const { firestore } = props;

    //guardado en la base de datos
    firebaseHelper.agregar(
      "promocionesCursos",
      firestore,
      nuevo,
      "/admin/promociones-cursos",
      history
    );
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const seleccionarCurso = (e) => {
    let datos = e.target.value.split("/");

    setState({
      ...state,
      curso: datos[1],
      curso_id: datos[0],
    });
  };

  return (
    <Fragment>
      <Header mensaje="NUEVA PROMOCION CURSO" />
      <div id="font" className="container">
        <div className="center" style={{ padding: "30px 0 30px 0" }}>
          <form onSubmit={agregar}>
            <div style={{ margin: "0 15%" }}>
              <div class="input-field col s12">
                <input
                  required
                  onChange={leerDato}
                  name="codigo"
                  id="codigo"
                  type="text"
                  class="validate"
                />
                <label for="codigo">Codigo de Promocion</label>
              </div>
              <div class="input-field col s12">
                <input
                  required
                  onChange={leerDato}
                  name="descuento"
                  id="descuento"
                  type="text"
                  class="validate"
                />
                <label for="descuento">% Descuento</label>
              </div>
              {!props.cursos ? (
                <Loader />
              ) : (
                <div class="input-field col s12">
                  <select required onChange={seleccionarCurso}>
                    <option value="" disabled selected>
                      Escoge un producto
                    </option>
                    {props.cursos.map((curso) => (
                      <option value={curso.id + "/" + curso.nombre}>
                        {curso.nombre}
                      </option>
                    ))}
                  </select>
                  <label></label>
                </div>
              )}
            </div>
            <button
              id="font-button"
              class="btn"
              type="submit"
              name="action"
              style={{ padding: "0 50px 0 50px", background: "#813A76 " }}
            >
              AGREGAR
            </button>
            <Link
              to={`/admin/promociones-cursos`}
              id="font-button"
              class="btn"
              style={{
                padding: "0 50px 0 50px",
                marginLeft: "20px",
                background: "#813A76 ",
              }}
            >
              REGRESAR
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "cursos",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    cursos: ordered.cursos,
  }))
)(NuevaPromocion);
