import React, {Fragment, useState, useEffect}  from 'react';
import Header from '../../../Components/SideNav';
import Swal from 'sweetalert2';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';
import Loader from '../../../Components/Loader';


const EditarCursoPresencial = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: ''
    });

    useFirestoreConnect([
        {
          collection: 'cursos',
          doc: id
        }
    ]);

    const curso = useSelector(
        ({ firestore: { data } }) => data.cursos && data.cursos[id]
    );

    useEffect(() => {
        if (curso !== undefined) {
            setState({
                nombre: curso.nombre,
                precio: curso.precio,
                fechaInicio: curso.fechaInicio,
                duracion: curso.duracion,
                cupo: curso.cupo,
            });
        }
    }, [curso]);

    const editado = e => {
        e.preventDefault();

        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                //redireccion
                editar();
            } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    const editar = () => {
        var cursos;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        cursos = {...state}

        //actualizacion en la base de datos
        firestore.update({
            collection: 'cursos',
            doc: id
        }, cursos ).then(() => {
            Swal.fire({
                title: 'Modificacion exitosa',
                text:'El campo fue modificado exitosamente',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon:'success'
            })
            history(`/admin/cursos`)
        });

    }

    //extrae los valores del input y los coloca en el state
    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR CURSO PRECENSIAL" />
            <div className="container center-align" style={{marginTop:'2%'}}>
                {!curso ? (
                    <Loader />
                ) : (
                    <form onSubmit={editado}>  
                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                            <label className='active' for="nombre" id="font-text2">Titulo</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.fechaInicio} name="fechaInicio" id="fechaInicio" type="text" class="validate" />
                            <label className='active' for="fechaInicio" id="font-text2">Fecha Inicial</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.duracion} name="duracion" id="duracion" type="text" class="validate" />
                            <label className='active' for="duracion" id="font-text2">Duración</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.precio} name="precio" id="precio" type="text" class="validate" />
                            <label className='active' for="precio" id="font-text2">Precio</label>
                        </div>

                        <div class="input-field">
                            <input required onChange={leerDato} defaultValue={state.cupo} name="cupo" id="cupo" type="text" class="validate" />
                            <label className='active' for="cupo" id="font-text2">Cupo</label>
                        </div>
                                    
                        <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                            <button id="font" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>EDITAR</button>
                            <Link to={`/admin/cursos`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
                        </div>
                    </form>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarCursoPresencial);
// export default compose(
//     firestoreConnect(props => [ 
//         { 
//             collection: 'cursos',
//             doc: props.match.params.id
//         } 
//     ]),
//     connect(({ firestore: { ordered }}, props ) => ({
//         cursos: ordered.cursos && ordered.cursos[0]
//     }))
// )(EditarCursoPresencial)