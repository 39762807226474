import React, {Fragment, useState, useEffect}  from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import firebaseHelper from '../../../Helpers/Firebase';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const EditarCategoria = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: ""
    });

    useFirestoreConnect([
        {
          collection: 'categoria',
          doc: id
        }
    ])

    const categoria = useSelector(
        ({ firestore: { data } }) => data.categoria && data.categoria[id]
    )

    useEffect(() => {
        if (categoria) {
            setState({
                nombre: categoria.nombre
            });
        }
    }, [categoria])

    const editar = e => {
        e.preventDefault();

        var editar = {...state};
        
        //extraer firestor de props
        const { firestore } = props;

        //actualizacion en la base de datos
        firebaseHelper.editar(id, 'categoria', firestore, editar, '/admin/categorias', history);
        
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR CATEGORIA" />
            <div id="font-text" className="container center" style={{marginTop:'0px'}}>
                {!categoria ? (
                    <Loader />
                ) : (
                    <form onSubmit={editar}>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} value={state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                <label class="active" for="nombre">Nombre Categoria</label>
                            </div>
                        </div>
                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>EDITAR</button>
                        <Link to={'/admin/categorias'} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                    </form>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarCategoria);

// export default compose(
//     firestoreConnect(props => [ 
//         { 
//             collection: 'categoria',
//             storeAs: 'categorias',
//             doc: props.match.params.id
//         } 
//     ]),
//     connect(({ firestore: { ordered }}, props ) => ({
//         categorias: ordered.categorias && ordered.categorias[0]
//     }))
// )(EditarCategoria)