import React, {Fragment, useState, useEffect}  from 'react';
import Header from '../../../Components/SideNav';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import M from 'materialize-css';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from '../../../Components/Loader';


const EditarProducto = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        especificacion: '',
        precio: '',
        cantidad: '',
        categoria: '',
        urlImagen: '',
        precioMayoreo: '',
        peso: 0
    });

    useFirestoreConnect([
        {
          collection: 'producto',
          doc: id
        }
    ])

    const producto = useSelector(
        ({ firestore: { data } }) => data.producto && data.producto[id]
    )

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);

        if (producto !== undefined) {
            setState({
                nombre: producto.nombre,
                precio: producto.precio,
                cantidad: producto.cantidad,
                especificacion: producto.especificacion,
                categoria: producto.categoria,
                urlImagen: producto.urlImagen,
                precioMayoreo: producto.precioMayoreo,
                peso: producto.peso
            });
        }
    },[producto]);

    const editar = e => {
        e.preventDefault();

        var producto;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        producto = {...state}

        //actualizacion en la base de datos
        firestore.update({
            collection: 'producto',
            doc: id
        }, producto ).then(() => editado());

    }
    
    const editado = () => {
        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Modificacion exitosa',
                    text:'El campo fue modificado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                })
                //redireccion
                history(`/admin/productos/${state.categoria}`)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const especificacion = (e, editor) => {
        setState({
            ...state,
            especificacion: editor.getData()
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR CATEGORIA" />
            {!producto ? (
                <Loader />
            ) : (
                <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                    <form onSubmit={editar}>

                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                <label class="active" for="nombre">Nombre del Producto</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.marca} name="marca" id="marca" type="text" class="validate" />
                                <label class="active" for="marca">Marca</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.precio} name="precio" id="precio" type="number" class="validate" />
                                <label class="active" for="precio">Precio Menudeo</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.precioMayoreo} name="precioMayoreo" id="precioMayoreo" type="number" class="validate" />
                                <label class="active" for="precioMayoreo">Precio Mayoreo</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.cantidad} name="cantidad" id="cantidad" type="number" class="validate" />
                                <label class="active" for="cantidad">Cantidad en Stock</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} defaultValue={producto.peso} name="peso" id="peso" type="number" class="validate" />
                                <label class="active" for="peso">Peso</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <h5>Espicificación</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                data= {String(producto.especificacion)}
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={especificacion}
                            />
                        </div>

                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>EDITAR</button>
                        <Link to={`/admin/productos/${producto.categoria}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
                    </form>
                </div>
            )}
        </Fragment>
    );
};

export default firestoreConnect() (EditarProducto);