// FIXME: evaluar antes de eliminar cualquier cosa (curso, categoria, etc) que no tenga nada enlazado
// fixme: ejemplo al eliminar un curso que no tenga ninguna unidad y al eliminar una unidad que no tenga ningun video
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// *** administrador *** //
import Login from "../Views/administrador/Login";
//usuarios
import Usuarios from "../Views/administrador/Usuarios/Usuarios";
import NuevoUsuario from "../Views/administrador/Usuarios/NuevoUsuario";
import EditarUsuario from "../Views/administrador/Usuarios/EditarUsuario";

//categorias
import Categorias from "../Views/administrador/Categorias/Categorias";
import NuevoCategoria from "../Views/administrador/Categorias/NuevaCategoria";
import EditarCategoria from "../Views/administrador/Categorias/EditarCategoria";

//productos
import Productos from "../Views/administrador/Productos/Productos";
import NuevoProducto from "../Views/administrador/Productos/NuevoProducto";
import EditarProducto from "../Views/administrador/Productos/EditarProducto";

//cursos
import Cursos from "../Views/administrador/Cursos/Cursos";
import NuevoCurso from "../Views/administrador/Cursos/NuevoCurso";
import EditarCurso from "../Views/administrador/Cursos/EditarCurso";
import EditarCursoPresencial from "../Views/administrador/Cursos/EditarCursoPresencial";
import EditarImagenPresencial from "../Views/administrador/Cursos/EditarImagenPresencial";

//unidades
import Unidades from "../Views/administrador/Cursos/Unidades/Unidades";
import NuevaUnidad from "../Views/administrador/Cursos/Unidades/NuevaUnidad";
import EditarUnidad from "../Views/administrador/Cursos/Unidades/EditarUnidad";
import VideosUnidad from "../Views/administrador/Cursos/Unidades/Videos";
import NuevaDescripcion from "../Views/administrador/Cursos/Unidades/AgregarDescripcion";
import EditarDescripcion from "../Views/administrador/Cursos/Unidades/EditarDescripcion";

//ventas
import Ventas from "../Views/administrador/Ventas/Ventas";
import Venta from "../Views/administrador/Ventas/Venta";

//inventario
import Inventario from "../Views/administrador/inventario/InventarioCategoria";
import InventarioProductos from "../Views/administrador/inventario/InventarioProductos";
import InventarioEditar from "../Views/administrador/inventario/InventarioEditar";

//imagenes
//productos
import ImagenesCategoria from "../Views/administrador/imagenes/Categorias";
import Imagenes from "../Views/administrador/imagenes/Productos/Imagenes";
import NuevaImagen from "../Views/administrador/imagenes/Productos/NuevaImagen";
import EditarImagen from "../Views/administrador/imagenes/Productos/EditarImagen";
import ImagenGaleria from "../Views/administrador/imagenes/Productos/Galeria";
//fin - productos
//servicios
import NuevaImagenServicios from "../Views/administrador/imagenes/Servicios/NuevaImagen";
import NuevaImagenInterna from "../Views/administrador/imagenes/Servicios/NuevaImagenInterna";
import EditarImagenServicios from "../Views/administrador/imagenes/Servicios/EditarImagen";
import EditarImagenInterna from "../Views/administrador/imagenes/Servicios/EditarImagenInterna";
import ImagenGaleriaServicios from "../Views/administrador/imagenes/Servicios/Galeria";
//fin - servicios
//fin - imagenes

//servicios
import Servicios from "../Views/administrador/Servicios/Servicios";
import NuevoServicio from "../Views/administrador/Servicios/NuevoServicio";
import EditarServicio from "../Views/administrador/Servicios/EditarServicios";
//fin - servicios

//envios
import Envios from "../Views/administrador/Envios/Envios";
//fin - envios

//preguntas
import Preguntas from "../Views/administrador/Preguntas/Preguntas";
import NuevaPregunta from "../Views/administrador/Preguntas/AgregarPregunta";
import EditarPregunta from "../Views/administrador/Preguntas/EditarPregunta";
//fin - preguntas

//promociones
// productos
import Promociones from "../Views/administrador/Promociones/Productos/Promociones";
import NuevaPromocion from "../Views/administrador/Promociones/Productos/NuevaPromocion";
// cursos
import PromocionesCursos from "../Views/administrador/Promociones/Cursos/Promociones";
import NuevaPromocionCurso from "../Views/administrador/Promociones/Cursos/NuevaPromocion";
//fin - promociones

//codigos de visita
import CodigosVisita from "../Views/administrador/CodigoVisita/CodigosVisitas";
import NuevoCodigoVisita from "../Views/administrador/CodigoVisita/NuevoCodigoVisita";
//fin - codigos de visita

//estados de envios
import EnviosEmpaquetar from "../Views/administrador/EnviosCompras/EnviosEmpaquetar";
import EnviosEnviar from "../Views/administrador/EnviosCompras/EnviosEnviar";
import EnviosEnviado from "../Views/administrador/EnviosCompras/EnviosEnviado";
import EnvioCompra from "../Views/administrador/EnviosCompras/EnvioCompra";
//fin - estados de envios

// ventas cursos
import VentasCursos from "../Views/administrador/VentasCursos/VentasCursos";
// fin - ventas cursos

const Router = () => (
  <BrowserRouter>
    <Routes>
      {/* administrador */}
      <Route exact path="/" element={<Login />} />
    </Routes>
    <Routes>
      {/* usuario */}
      <Route path="/admin/usuarios" element={<Usuarios />} />
      <Route path="/admin/nuevo-usuario" element={<NuevoUsuario />} />
      <Route path="/admin/editar-usuario/:id" element={<EditarUsuario />} />
      {/* fin - usuario */}
      {/* categorias */}
      <Route path="/admin/categorias" element={<Categorias />} />
      <Route path="/admin/nueva-categoria" element={<NuevoCategoria />} />
      <Route path="/admin/editar-categoria/:id" element={<EditarCategoria />} />
      {/* fin - categorias */}
      {/* productos */}
      <Route path="/admin/productos/:id" element={<Productos />} />
      <Route path="/admin/nuevo-producto/:id" element={<NuevoProducto />} />
      <Route
        path="/admin/editar-producto/:id/:idSubCategoria"
        element={<EditarProducto />}
      />
      {/* fin - productos */}
      {/* servicios */}
      <Route path="/admin/servicios" element={<Servicios />} />
      <Route path="/admin/nuevo-servicio" element={<NuevoServicio />} />
      <Route path="/admin/editar-servicio/:id" element={<EditarServicio />} />
      {/* fin - servicios */}
      {/* ventas */}
      <Route path="/admin/ventas" element={<Ventas />} />
      <Route path="/admin/venta/:id" element={<Venta />} />
      {/* fin - ventas */}
      {/* cursos */}
      <Route exact path="/admin/cursos" element={<Cursos />} />
      <Route exact path="/admin/curso-editar/:id" element={<EditarCurso />} />
      <Route
        exact
        path="/admin/curso-presencial-editar/:id"
        element={<EditarCursoPresencial />}
      />
      <Route
        exact
        path="/admin/curso-presencial-imagenes/:id"
        element={<EditarImagenPresencial />}
      />
      <Route exact path="/admin/nuevo-curso" element={<NuevoCurso />} />
      {/* unidades */}
      <Route exact path="/admin/unidades/:id" element={<Unidades />} />
      <Route
        exact
        path="/admin/editar-unidad/:id/:unidad"
        element={<EditarUnidad />}
      />
      <Route exact path="/admin/nueva-unidad/:id" element={<NuevaUnidad />} />
      <Route exact path="/admin/videos-unidad/:id" element={<VideosUnidad />} />
      <Route
        exact
        path="/admin/nueva-descripcion/:id/:posicion"
        element={<NuevaDescripcion />}
      />
      <Route
        exact
        path="/admin/editar-descripcion/:id/:posicion"
        element={<EditarDescripcion />}
      />
      {/* fin - unidades */}
      {/* fin - cursos */}
      {/* inventario */}
      <Route exact path="/admin/inventario" element={<Inventario />} />
      <Route
        exact
        path="/admin/inventario/:id"
        element={<InventarioProductos />}
      />
      <Route
        exact
        path="/admin/inventario-editar/:id"
        element={<InventarioEditar />}
      />
      {/* fin - inventario */}
      {/* imagenes */}
      {/* productos */}
      <Route
        exact
        path="/admin/imagenes-categorias"
        element={<ImagenesCategoria />}
      />
      <Route exact path="/admin/imagenes/:id" element={<Imagenes />} />
      <Route
        path="/admin/imagenes/nueva-imagenes/:idCategoria/:id"
        element={<NuevaImagen />}
      />
      <Route
        path="/admin/imagenes/editar-imagenes/:idCategoria/:id"
        element={<EditarImagen />}
      />
      <Route
        path="/admin/galeria/:idCategoria/:id"
        element={<ImagenGaleria />}
      />
      {/* fin - productos */}
      {/* servicios */}
      <Route
        path="/admin/imagenes/nueva-imagen-interna-servicios/:id"
        element={<NuevaImagenInterna />}
      />
      <Route
        path="/admin/imagenes/nueva-imagen-servicios/:id"
        element={<NuevaImagenServicios />}
      />
      <Route
        path="/admin/imagenes/editar-imagen-interna-servicios/:id"
        element={<EditarImagenInterna />}
      />
      <Route
        path="/admin/imagenes/editar-imagen-servicios/:id"
        element={<EditarImagenServicios />}
      />
      <Route
        path="/admin/galeria-servicios/:id"
        element={<ImagenGaleriaServicios />}
      />
      {/* fin - servicios */}
      {/* fin - imagenes */}
      {/* envios */}
      <Route exact path="/admin/envios-costos" element={<Envios />} />
      {/* fin - envios */}
      {/* preguntas */}
      <Route exact path="/admin/preguntas/:id" element={<Preguntas />} />
      <Route
        exact
        path="/admin/nueva-pregunta/:id"
        element={<NuevaPregunta />}
      />
      <Route
        exact
        path="/admin/editar-pregunta/:id/:idCurso"
        element={<EditarPregunta />}
      />
      {/* fin - preguntas */}

      {/* promociones */}
      {/* productos */}
      <Route
        exact
        path="/admin/promociones-productos"
        element={<Promociones />}
      />
      <Route exact path="/admin/nueva-promocion" element={<NuevaPromocion />} />
      {/* fin - productos */}
      {/* cursos */}
      <Route
        exact
        path="/admin/promociones-cursos"
        element={<PromocionesCursos />}
      />
      <Route
        exact
        path="/admin/nueva-promocion-curso"
        element={<NuevaPromocionCurso />}
      />
      {/* fin - cursos */}
      {/* fin - promociones */}

      {/* codigo de visita */}
      <Route
        exact
        path="/admin/codigos-de-visita"
        element={<CodigosVisita />}
      />
      <Route exact path="/admin/nuevo-codigo" element={<NuevoCodigoVisita />} />
      {/* fin - codigo de visita */}
      {/* estados de envios */}
      <Route
        exact
        path="/admin/envios-empaquetar"
        element={<EnviosEmpaquetar />}
      />
      <Route exact path="/admin/envios-enviar" element={<EnviosEnviar />} />
      <Route exact path="/admin/envios-enviados" element={<EnviosEnviado />} />
      <Route exact path="/admin/estado-envio/:id" element={<EnvioCompra />} />
      {/* fin - estados de envios */}
      {/* ventas cursos */}
      <Route exact path="/admin/ventas-cursos" element={<VentasCursos />} />
      {/* fin - ventas cursos */}
      {/* fin - administrador */}
    </Routes>
  </BrowserRouter>
);

export default Router;
