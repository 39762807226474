import React, {Fragment, useState, useEffect}  from 'react';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { Link, useParams } from 'react-router-dom';

import conekta from '../../../Helpers/Conekta';
import Header from '../../../Components/SideNav';


const Venta = () => {
    const { id } = useParams();
    const [state, setState] = useState({
        data: false
    });

    useEffect(() => {
        buscar();
    }, []);

    const buscar = async () => {
        var res = await conekta.obtenerVenta(id);
        if (res.type) {
            setState({
                data: res.res
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Error al obtener venta',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        }
    }

    return (
        <Fragment>
            <Header mensaje="INFORMACION VENTA" />

            <div className="container">
                {!state.data ? (
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container" style={{marginBottom:"100px"}}>
                        <Link onClick={
                            () => { window.close() }
                        } id="font-button" class="btn" style={{padding:'0 50px 0 50px', margin:'50px 0px', background:'#813A76 '}}>REGRESAR</Link>

                        <div>
                            <div style={{border:"1px solid grey", borderBottom:"0px"}}>
                                <p className="titulo_ventas">Resumen de la orden</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">ESTADO</p>
                                </div>
                                <div className="div_ventas_2">
                                    {state.data.payment_status !== undefined ? (
                                        <p style={{color:"green", margin:"0px", padding:"15px"}}>Pagado</p>
                                    ) : (
                                        <p style={{color:"red", margin:"0px", padding:"15px"}}>Declinado</p>
                                    )}
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_3">
                                    <p className="parrafo_1">MONTO</p>
                                </div>
                                <div className="div_ventas_4">
                                    <p className="parrafo_1">$ {(state.data.amount / 100).toFixed(2)}</p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">FECHA DE CREACIÓN</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">{
                                        new Date(state.data.created_at * 1000).getDate() + "/" +
                                        new Date(state.data.created_at * 1000).getMonth() + "/" +
                                        new Date(state.data.created_at * 1000).getFullYear() + " " +
                                        new Date(state.data.created_at * 1000).getHours() + ":" + 
                                        new Date(state.data.created_at * 1000).getMinutes()
                                    }</p>
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:"50px"}}>
                            <div style={{border:"1px solid grey", borderBottom:"0px"}}>
                                <p className="titulo_ventas">Cliente</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">NOMBRE</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">{state.data.customer_info.name}</p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_3">
                                    <p className="parrafo_1">CORREO ELECTRÓNICO</p>
                                </div>
                                <div className="div_ventas_4">
                                    <p className="parrafo_1">{state.data.customer_info.email}</p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">TELÉFONO</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">{state.data.customer_info.phone}</p>
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:"50px"}}>
                            <div style={{border:"1px solid grey", borderBottom:"0px"}}>
                                <p className="titulo_ventas">Tarjeta</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">NOMBRE</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            state.data.charges.data[0].payment_method.name
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_3">
                                    <p className="parrafo_1">NUMERO DE LA TARJETA</p>
                                </div>
                                <div className="div_ventas_4">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            "**** **** ****" + state.data.charges.data[0].payment_method.last4
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_5">
                                    <p className="parrafo_1">FECHA DE EXPIRACIÓN</p>
                                </div>
                                <div className="div_ventas_6">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            state.data.charges.data[0].payment_method.exp_month + "/" +state.data.charges.data[0].payment_method.exp_year
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_5">
                                    <p className="parrafo_1">CODIGO DE AUTORIZACIÓN</p>
                                </div>
                                <div className="div_ventas_6">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            state.data.charges.data[0].payment_method.auth_code
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_5">
                                    <p className="parrafo_1">TARJETA</p>
                                </div>
                                <div className="div_ventas_6">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            state.data.charges.data[0].payment_method.brand.toUpperCase()
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">BANCO EMISOR</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            state.data.charges.data[0].payment_method.account_type
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:"50px"}}>
                            <div style={{border:"1px solid grey", borderBottom:"0px"}}>
                                <p className="titulo_ventas">Artículos</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_7">
                                    <Table striped bordered hover >
                                        <thead>
                                            <tr>
                                                <th>ARTÍCULO</th>
                                                <th>CANTIDAD</th>
                                                <th>PRECIO UNITARIO</th>
                                            </tr>
                                        </thead>
                                        <tbody id="font">
                                            {state.data.line_items.data.map(dato => (
                                                <tr key={dato.id}>
                                                    <td>{dato.name}</td>
                                                    <td>{dato.quantity}</td>
                                                    <td>$ {parseFloat(dato.unit_price / 100).toFixed(2)}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:"50px"}}>
                            <div style={{border:"1px solid grey", borderBottom:"0px"}}>
                                <p className="titulo_ventas">Comisión</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1">MONTO</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1">$ {(state.data.amount / 100).toFixed(2)}</p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_3">
                                    <p className="parrafo_1">COMISIÓN</p>
                                </div>
                                <div className="div_ventas_4">
                                    <p className="parrafo_1">
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            "$" + parseFloat(state.data.charges.data[0].fee / 100).toFixed(2)
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div className="div_ventas_1">
                                    <p className="parrafo_1" style={{color:"green"}}>TOTAL A DEPOSITAR</p>
                                </div>
                                <div className="div_ventas_2">
                                    <p className="parrafo_1" style={{color:"green"}}>
                                        {state.data.charges === undefined ? (
                                            "Sin cargos"
                                        ) : (
                                            "$" + (parseFloat(state.data.amount / 100) - parseFloat(state.data.charges.data[0].fee / 100)).toFixed(2)
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Venta;