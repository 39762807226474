import React, {Fragment, useState}  from 'react';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';

import conekta from '../../../Helpers/Conekta';
import Header from '../../../Components/SideNav';


const Ventas = () => {
    const [state, setState] = useState({
        rango: [
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), 
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)
        ], 
        ventas: "",
        total: ""
    });

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const buscar = async () => {
        let fecha_inicial = Math.ceil(state.rango[0].getTime() / 1000);
        let fecha_final = Math.ceil(state.rango[1].getTime() / 1000);
        setState({
            ...state,
            ventas: false
        });

        var res = await conekta.obtenerVentas(fecha_inicial, fecha_final);
        if (res.type) {
            setState({
                ...state,
                ventas: res.res.data,
                total: res.total,
                total_cargos: res.total_cargos
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Error al buscar ventas',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        }
    }

    return (
        <Fragment>
            <Header mensaje="VENTAS" />

            <div className="container">
                <DateRangePickerComponent placeholder="Selecciona el rango de fechas"
                    name="rango"
                    onChange={leerDato}
                    startDate={new Date(state.rango[0])}
                    endDate={new Date(state.rango[1])}
                    minDays={2}
                    maxDays={31}
                    format="dd-MMM-yyyy"
                ></DateRangePickerComponent>
                <button id="font-button" class="btn" onClick={buscar} style={{padding:'0 50px 0 50px', margin:"50px", background:'#813A76'}}>BUSCAR</button>
            </div>

            <div className="container center" id="datos_ventas">
                {state.ventas === "" ? (
                    <h2>Selecciona un rango de fechas</h2>
                ) : (
                    !state.ventas ? (
                        <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                            <div class="preloader-wrapper big active">
                                <div class="spinner-layer spinner-red darken-4-only">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="gap-patch">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        state.ventas.length === 0 ? (
                            <h2>Sin ventas en el rango seleccionado</h2>
                        ) : (
                            <div>
                                <Table striped bordered hover style={{marginBottom:'50px'}}>
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Estado de pago</th>
                                            <th>Tipo de cargo</th>
                                            <th>Cliente</th>
                                            <th>Monto</th>
                                            <th>Cargos</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody id="font">
                                        {state.ventas.map(dato => (
                                            <tr key={dato.id}>
                                                <td>{
                                                    new Date(dato.created_at * 1000).getDate() + "/" +
                                                    new Date(dato.created_at * 1000).getMonth() + "/" +
                                                    new Date(dato.created_at * 1000).getFullYear() + " " +
                                                    new Date(dato.created_at * 1000).getHours() + ":" + 
                                                    new Date(dato.created_at * 1000).getMinutes()
                                                }</td>
                                                <td>
                                                    {
                                                        dato.last_payment_info === undefined ? (
                                                            <p style={{color:"grey"}}>Sin cargo</p>
                                                        ) : (
                                                            dato.last_payment_info.status === "paid" ? (
                                                                <p style={{color:"green"}}>Pagado</p>
                                                            ) : (
                                                                <p style={{color:"red"}}>Declinado</p>
                                                            )
                                                        )
                                                        
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dato.last_payment_info === undefined ? (
                                                            <p style={{color:"grey"}}>Sin cargo</p>
                                                        ) : (
                                                            dato.last_payment_info.payment_method === "CreditCardPayment" ? (
                                                                <p>Tarjeta</p>
                                                            ) : (
                                                                <p>Otro</p>
                                                            )
                                                        )
                                                        
                                                    }
                                                </td>
                                                <td>{dato.customer_info.name}</td>
                                                <td>${parseFloat(dato.amount / 100).toFixed(2)}</td>
                                                <td>{
                                                    dato.charges === undefined ? (
                                                        <p style={{color:"grey"}}>Sin cargo</p>
                                                    ) : (
                                                        "$" + parseFloat(dato.charges.data[0].fee / 100).toFixed(2)
                                                    )
                                                }</td>
                                                <td>
                                                    <a style={{marginLeft:'14%'}} target="blank" href={`/admin/venta/${dato.id}`}><i class="far fa-eye" style={{color:'#813A76 '}}></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div style={{textAlign:"left"}}>
                                    <h2>Total: $ {parseFloat(state.total).toFixed(2)}</h2>
                                    <h2>Cargos totales: $ {state.total_cargos.toFixed(2)}</h2>
                                    <h2>Total con cargos: $ {parseFloat(state.total - state.total_cargos).toFixed(2)}</h2>
                                </div>
                            </div>
                        )
                    )
                )}
            </div>

        </Fragment>
    );
};

export default Ventas;