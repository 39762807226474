import React, {Fragment}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import firebaseHelper from '../../../Helpers/Firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const Usuarios = props => {

    const eliminar = (id) => {
        const { firestore } = props;
        firebaseHelper.eliminar(id, 'usuario', firestore);
    }

    return (
        <Fragment>
            <Header mensaje="USUARIOS" />

            <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                <Link to={'/admin/nuevo-usuario'} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR CAMPO</Link>
            </div>

            <div className="container">
                {!props.usuarios ? (
                    <Loader />
                ) : (
                    props.usuarios.length === 0 ? (
                        <div className="center">
                            <h2 id="font">No hay usuarios registrados</h2>
                        </div>
                    ) : (
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.usuarios.map(usuario => (
                                    <tr key={usuario.id}>
                                        <td>{usuario.tipo}</td>
                                        <td>{usuario.nombre}</td>
                                        <td>
                                            <Link style={{marginLeft:'14%'}} to={`/admin/editar-usuario/${usuario.id}`}><i class="material-icons" style={{color:'#813A76 '}}>create</i></Link>
                                            <a href="#!" onClick={ () => eliminar(usuario.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76 '}}>delete</i></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
            
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'usuario',
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        usuarios: ordered.usuario
    }))
)(Usuarios);