import React, {Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../../../Components/Loader';



const Categorias = props => {

    const busqueda = (id) => {
        const { firestore } = props;

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("subCategoria")
                .where("categoria", "==", id)
                .get()
                .then(function(querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        querySnapshot.forEach(function (doc) {
                            ////console.log(doc.id, ' => ', doc.data());
                            resolve(doc);
                        });
                    }
                }).catch(function(error) {
                    ////console.log("Error getting document:", error);
                    resolve(2);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return acceso;
        }

        guardarState1().then(resultado => {
            if (resultado === 1) {
                eliminar(id);
            } else if (resultado === 2) {
                Swal.fire(
                    'Error!',
                    'Opss, Error al eliminar la variante',
                    'error'
                )
            } else {
                Swal.fire(
                    'Error!',
                    'Opss, No se puede eliminar la variante! <br/> La variante cuenta con productos <br/> Primero elimine los productos',
                    'error'
                )
            }
        })
    }

    const eliminar = (id) => {
        const { firestore } = props;
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Eliminado con exito',
                    text:'El campo fue eliminado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                })
                //eliminar frase
                firestore.delete({
                    collection: 'categoria',
                    doc: id
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    return (
        <Fragment>
            <Header mensaje="IMAGENES" />

            <div className="container" style={{padding: '30px 0 30px 0'}}>
                <h3>Categorias</h3>
                {!props.categoria ? (
                    <Loader />
                ) : (
                    props.categoria.length === '0' ? (
                        <span></span>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.categoria.map(categoria => (
                                    <tr key={categoria.id}>
                                        <td>{categoria.nombre}</td>
                                        <td>
                                        <Link style={{marginLeft:'14%'}} to={`/admin/imagenes/${categoria.id}`}><i class="material-icons" style={{color:'#813A76'}}>remove_red_eye</i></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )

                )}


                <h3>Servicios</h3>
                {!props.servicios ? (
                    <Loader />
                ) : (
                    props.servicios.length === '0' ? (
                        <span></span>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Galeria</th>
                                    <th>Interna</th>
                                    <th>Principal</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.servicios.map(servicio => (
                                    <tr key={servicio.id}>
                                        <td>{servicio.nombre}</td>
                                        <td>
                                            <a style={{marginLeft:'14%'}} href={`/admin/galeria-servicios/${servicio.id}`}><i  style={{color:'#813A76'}} class="far fa-images"></i></a>
                                        </td>
                                        <td>
                                            {servicio.urlInterna === "" ? (
                                                <a style={{marginLeft:'14%'}} href={`/admin/imagenes/nueva-imagen-interna-servicios/${servicio.id}`}><i class="material-icons" style={{color:'#813A76'}}>add_circle</i></a>
                                            ) : (
                                                <a style={{marginLeft:'14%'}} href={`/admin/imagenes/editar-imagen-interna-servicios/${servicio.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></a>
                                            )}
                                        </td>
                                        <td>
                                            {servicio.urlImagen === "" ? (
                                                <a style={{marginLeft:'14%'}} href={`/admin/imagenes/nueva-imagen-servicios/${servicio.id}`}><i class="material-icons" style={{color:'#813A76'}}>add_circle</i></a>
                                            ) : (
                                                <a style={{marginLeft:'14%'}} href={`/admin/imagenes/editar-imagen-servicios/${servicio.id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )

                )}
            </div>
            
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria'
        },
        {
            collection: 'servicios'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categoria: ordered.categoria,
        servicios: ordered.servicios
    }))
)(Categorias);