import React from 'react';

const Loader = () => {
    return (
        <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", height:"100%", maxHeight:"90vh", minHeight:"10vh"}}>
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;