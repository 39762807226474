import React, {Fragment, useState, useEffect}  from 'react';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';

import Header from '../../../../Components/SideNav';
import restAPI from '../../../../Helpers/RestAPI';
import Loader from '../../../../Components/Loader';


const EditarImagen = props => {
    const { id, idCategoria } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        file1: '',
        uploadValue: 0,
        picture: null,
        nombre: '',
        prueba: ''
    });

    useFirestoreConnect([
        {
          collection: 'producto',
          doc: id
        }
    ]);

    const producto = useSelector(
        ({ firestore: { data } }) => data.producto && data.producto[id]
    );

    useEffect(() => {
        if (producto !== undefined) {
            const productoCopia = {
                categoria: producto.categoria,
                urlImagen: producto.urlImagen,
            }
    
            setState({
                ...state,
                prueba: '1',
                nombre: producto.nombre,
                producto: productoCopia
            });
        }
    }, [producto]);

    const handelUpload = e => {
        e.preventDefault();

        setState({
            ...state,
            file1: e.target.files[0]
        });

        precarga();
    }

    const precarga = () => {
        var file = state.file1,
        imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const subirDB = async file => {
        const { firestore } = props;
        const { firebase } = props;
        var datosState = {...state};
        
        var nombre = state.nombre;
        var file1 = state.file1;

        var storage = firebase.storage();

        function upload(datos) {
            datosState.producto.urlImagen = datos.dataURL;

            firestore.update({
                collection: 'producto',
                doc: id
            }, datosState.producto ).then(() => {
                // File deleted successfully
                Swal.fire({
                    title: 'Modificacion exitosa',
                    text:'El campo fue modificado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                }).then((result) => {
                    //redireccion
                    history(`/admin/imagenes/${idCategoria}`);
                })
            });
        }

        if (file === '1') {
            if ((state.file1 === '') || (state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                Swal.fire({
                    title: '¿Esta seguro de actualizar este campo?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#2DCD22',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.value) {
                        preloader();
                        const storageRefEliminar = storage.refFromURL(producto.urlImagen);
                        storageRefEliminar.delete().then(async function() {
                            const api = await restAPI.upload({
                                data: file1,
                                name: nombre
                            });
                            if (api.success) {
                                upload(api);
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    text: api.type,
                                    confirmButtonColor: '#2DCD22',
                                    confirmButtonText: 'Continuar',
                                    icon: 'error'
                                })
                            }
                        }).catch(function(error) {
                            //error al eliminra
                            console.log(error);
                            Swal.fire({
                                title: 'Error',
                                text: 'Actualizacion exitosa pero error al aliminar archivo 1',
                                confirmButtonColor: '#2DCD22',
                                confirmButtonText: 'Continuar',
                                icon: 'error'
                            })
                        });
                    } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                    ) {
                        Swal.fire({
                            title: 'Cancelado',
                            text: 'El campo sigue intacto :)',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon: 'error'
                        })
                    }
                })
            }
        }
    }

    const preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }


    return (
        <Fragment>
            <Header mensaje="imagen a editar" />
            <div className="container center-align" style={{marginTop:'50px'}}>
                <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>AGREGAR IMAGEN A PRODUCTO</h2>
                <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                    
                </div>

                {!producto ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div className="col s4">    
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>File</span>
                                    <input onChange={handelUpload} name="file" type="file"/>
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                </div>
                            </div>
                            <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                                <div id="font" class="btn" onClick={() => subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76'}}>ACTUALIZAR</div>
                                <Link to={`/admin/imagenes/${idCategoria}`} id="font" class="btn" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#813A76'}}>REGRESAR</Link>
                            </div>
                        </div>
                        <div className="col s8">
                            <img alt="imagen subida" id="imgSalida" width="100%" src={producto.urlImagen}></img>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarImagen);

// export default compose(
//     firestoreConnect(props => [ 
//         { 
//             collection: 'producto',
//             storeAs: 'producto',
//             doc: props.match.params.id
//         } 
//     ]),
//     connect(({ firestore: { ordered }}, props ) => ({
//         producto: ordered.producto && ordered.producto[0]
//     }))
// )(App);