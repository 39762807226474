import React, {Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const Categorias = props => {
    const busqueda = (id) => {
        const { firestore } = props;

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("producto")
                .where("categoria", "==", id)
                .get()
                .then(function(querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        querySnapshot.forEach(function (doc) {
                            //console.log(doc.id, ' => ', doc.data());
                            resolve(3);
                        });
                    }
                }).catch(function(error) {
                    //console.log("Error getting document:", error);
                    resolve(2);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return acceso;
        }

        guardarState1().then(resultado => {
            if (resultado === 1) {
                eliminar(id);
            } else if (resultado === 2) {
                Swal.fire(
                    'Error!',
                    'Opss, Error al eliminar la variante',
                    'error'
                )
            } else if (resultado === 3) {
                Swal.fire(
                    'Error!',
                    'Opss, No se puede eliminar la variante! <br/> La variante cuenta con productos <br/> Primero elimine los productos',
                    'error'
                )
            }
        })
    }

    const eliminar = (id) => {
        const { firestore } = props;

        firebaseHelper.eliminar(id, 'categoria', firestore);
    }

    return (
        <Fragment>
            <Header mensaje="CATEGORIAS" />

            <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                <Link to={'/admin/nueva-categoria'} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR CATEGORIA</Link>
            </div>

            <div className="container" style={{padding: '0 0 100px 0'}}>
                {!props.categoria ? (
                    <Loader />
                ) : (
                    props.categoria.length === '0' ? (
                        <h2>sin categorias</h2>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.categoria.map(dato => (
                                    <tr key={dato.id}>
                                        <td>{dato.nombre}</td>
                                        <td>
                                            <a style={{marginLeft:'14%'}} href={`/admin/productos/${dato.id}`}><i class="material-icons" style={{color:'#813A76'}}>remove_red_eye</i></a>
                                            <Link style={{marginLeft:'14%'}} to={`/admin/editar-categoria/${dato.id}`}><i class="material-icons" style={{color:'#813A76 '}}>create</i></Link>
                                            <div onClick={ () => busqueda(dato.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76 '}}>delete</i></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
            
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categoria: ordered.categoria
    }))
)(Categorias);
