import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { firestoreConnect } from "react-redux-firebase";

import Header from "../../../Components/SideNav";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import restAPI from "../../../Helpers/RestAPI";

import firebaseHelper from "../../../Helpers/Firebase";

const NuevoCodigoVisita = (props) => {
  const history = useNavigate();
  const [state, setState] = useState({
    file1: "",
    picture: null,
    codigo: "",
    clienta: "",
    fecha: "",
    prueba: "",
    imagenesArchivos: [],
    urlImagenes: [],
  });
  const [imagenes, setImagenes] = useState([]);

  const handelUpload = (e) => {
    setState({
      ...state,
      file1: e.target.files[0],
      imagenesArchivos: e.target.files,
    });

    precarga(e.target.files, 0, []);
  };

  const precarga = (files, index, filesRender) => {
    let imagenesCopia = [...filesRender];
    var file = files[index];

    var imageType = /image.*/;

    if (!file.type.match(imageType)) return;

    var reader = new FileReader();
    var result;

    reader.onload = (e) => {
      result = e.target.result;
      imagenesCopia.push(result);

      if (index < files.length - 1) {
        let copiaIndex = index + 1;
        precarga(files, copiaIndex, imagenesCopia);
      } else {
        setImagenes(imagenesCopia);
      }
    };

    reader.readAsDataURL(file);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const subirDB = async (contador) => {
    const { firestore } = props;

    if (state.codigo === "") {
      Swal.fire("Error", "No hay archivo seleccionado", "error");
    } else {
      preloader();
      console.log(contador);
      var file1 = state.imagenesArchivos[contador];
      let urlImagenes = state.urlImagenes;

      const api = await restAPI.uploadCodigo({
        data: file1,
        name: state.codigo,
      });

      if (api.success) {
        urlImagenes.push(api.dataURL);

        setState({
          ...state,
          urlImagenes,
        });

        if (contador < state.imagenesArchivos.length - 1) {
          subirDB(contador + 1);
        } else {
          let codigoVisita = {
            codigo: state.codigo,
            clienta: state.clienta,
            fecha: state.fecha,
            visto: 0,
            imagenes: urlImagenes,
          };

          firebaseHelper.agregar(
            "codigosVisitas",
            firestore,
            codigoVisita,
            "/admin/codigos-de-visita",
            history
          );
        }
      } else {
        Swal.fire({
          title: "Error",
          text: api.type,
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      }
    }
  };

  const preloader = () => {
    var preloader = (
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-red">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    );
    ReactDOM.render(preloader, document.getElementById("preloader"));
  };

  const eliminarImagen = (index) => {
    let imagenesArchivosCopia = [...state.imagenesArchivos];
    let imagenesCopia = [...imagenes];

    imagenesArchivosCopia.splice(index, 1);
    imagenesCopia.splice(index, 1);

    setState({
      ...state,
      imagenes: imagenesCopia,
      imagenesArchivos: imagenesArchivosCopia,
    });
  };

  return (
    <Fragment>
      <Header mensaje="imagen a editar" />
      <div className="container center-align" style={{ marginTop: "50px" }}>
        <h2 id="font" style={{ marginBottom: "30px", marginTop: "0" }}>
          AGREGAR IMAGEN A PRODUCTO
        </h2>
        <div
          id="preloader"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            justifyItems: "center",
          }}
        ></div>
        <div className="row">
          <div class="input-field col s12">
            <input
              required
              onChange={leerDato}
              name="codigo"
              id="codigo"
              type="text"
              class="validate"
            />
            <label for="codigo">Codigo</label>
          </div>
          <div class="input-field col s12">
            <input
              required
              onChange={leerDato}
              name="clienta"
              id="clienta"
              type="text"
              class="validate"
            />
            <label for="clienta">Nombre Cliente</label>
          </div>
          <div class="input-field col s12">
            <input
              required
              onChange={leerDato}
              name="fecha"
              id="fecha"
              type="text"
              class="validate"
            />
            <label for="fecha">Fecha de visita</label>
          </div>
          <div className="col s12">
            <div className="file-field input-field">
              <div className="btn">
                <span>File</span>
                <input
                  onChange={handelUpload}
                  name="file"
                  type="file"
                  multiple
                />
              </div>
              <div className="file-path-wrapper">
                <input
                  onChange={handelUpload}
                  multiple
                  className="file-path validate"
                  type="text"
                  placeholder="Seleccione una foto o arrastre una foto"
                />
              </div>
            </div>
          </div>
          <div className="col s12">
            {imagenes.map((imagen, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ width: "33.33333%" }}>
                  <img src={imagen} alt="" style={{ width: "100%" }} />
                </div>
                <div style={{ width: "33.33333%" }}>
                  <a
                    href="#!"
                    onClick={() => eliminarImagen(index)}
                    style={{ marginLeft: "14%" }}
                  >
                    <i class="material-icons" style={{ color: "#813A76" }}>
                      delete
                    </i>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="col s12">
            <Link
              to={`/admin/codigos-de-visita`}
              id="font"
              class="btn"
              style={{
                margin: "50px",
                padding: "0 50px 0 50px",
                background: "#813A76",
              }}
            >
              REGRESAR
            </Link>
            <div
              id="font"
              class="btn"
              onClick={() => {
                subirDB(0);
              }}
              style={{
                margin: "50px",
                padding: "0 50px 0 50px",
                background: "#813A76",
              }}
            >
              AGREGAR
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default firestoreConnect()(NuevoCodigoVisita);
