import React, {Fragment, useState, useEffect}  from 'react';
import CryptoJS from "react-native-crypto-js";

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';

import firebaseHelper from '../../../Helpers/Firebase';

import { Link, useParams, useNavigate } from 'react-router-dom';

const EditarUsuario = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        correo: '',
        password: '',
        tipo: '',
        tipoMostrar: '',
        nombre: ''
    });

    useFirestoreConnect([
        {
          collection: 'usuario',
          doc: id
        }
    ]);

    const usuario = useSelector(
        ({ firestore: { data } }) => data.usuario && data.usuario[id]
    );

    useEffect(() => {
        if (usuario !== undefined) {
            setState({
                password: usuario.password,
                nombre: usuario.nombre,
                correo: usuario.correo,
                tipo: usuario.tipo
            });
        }
    },[usuario]);

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const editar = e => {
        e.preventDefault();

        var tipoMostrar;

        if (state.tipo === '0') {
            tipoMostrar = "Administrador"
        } else if (state.tipo === '1') {
            tipoMostrar = "Otro"
        }

        if (state.password !== usuario.password) {
            let ciphertext = CryptoJS.AES.encrypt(state.password, 'Y2Fhdg==').toString();
            setState({
                ...state,
                password: ciphertext
            });
        }

        setState({
            tipoMostrar: tipoMostrar
        }, () => {
            //extraer valores del state
            var editar = {...state};
    
            //extraer firestor de props
            const { firestore } = props;
    
            //actualizacion en la base de datos
            firebaseHelper.editar(usuario.id, 'usuario', firestore, editar, '/admin/usuarios', history);
        });
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR USUARIO" />
            {!usuario ? (
                <Loader />
            ) : (
                <div id="font-text" className="container center" style={{marginTop:'0px'}}>
                    <form onSubmit={editar}>
                        <div style={{margin:'0 15%'}}>
                            <p id="font-text-b" >Tipo Usuario</p>
                            <div id="tipo" style={{display:'flex', justifyContent:'center'}}>
                                <div className="row">
                                    <p style={{marginLeft:'10px'}}>
                                        <label style={{marginLeft:'30px'}}>
                                            {state.tipo === '0' ? (
                                                <input checked required onChange={leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                            ) : (
                                                <input required onChange={leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                            )}
                                            <span>Administrador</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label style={{marginLeft:'30px'}}>
                                            {state.tipo === '1' ? (
                                                <input checked required onChange={leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                            ) : (
                                                <input required onChange={leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                            )}
                                            <span>Otros</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} value={state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                <label class="active" for="nombre">Nombre</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="correo" value={state.correo} id="correo" type="email" class="validate" />
                                <label class="active" for="correo">Correo</label>
                            </div>
                            <div class="input-field col s12">
                                <input onChange={leerDato} name="password" id="password" type="password" class="validate" />
                                <label for="password">Password</label>
                            </div>
                        </div>
                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>EDITAR</button>
                        <Link to={'/admin/usuarios'} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                    </form>
                </div>
            )}
        </Fragment>
    );
};

export default firestoreConnect() (EditarUsuario);