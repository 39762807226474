import React, {Fragment, useState, useEffect}  from 'react';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Header from '../../../../Components/SideNav';
import firebaseHelper from '../../../../Helpers/Firebase';

import Loader from '../../../../Components/Loader';

const EditarUnidad = props => {
    const { id, unidad } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: ''
    });

    useFirestoreConnect([
        {
          collection: 'unidades',
          doc: id
        }
    ]);

    const unidades = useSelector(
        ({ firestore: { data } }) => data.unidades && data.unidades[id]
    );

    useEffect(() => {
        if (unidades !== undefined) {
            setState(unidades);
        }
    }, [unidades]);

    const editar = e => {
        e.preventDefault();

        var editar = {...state};
        
        //extraer firestor de props
        const { firestore } = props;

        //actualizacion en la base de datos
        firebaseHelper.editar(id, 'unidades', firestore, editar, `/admin/unidades/${unidad}`, history);
        
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="EDITAR UNIDAD" />
            <div id="font" className="container">
                {!unidades ? (
                    <Loader />
                ) : (
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={editar}>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={leerDato} defaultValue={unidades.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                    <label className='active' for="nombre">Nombre</label>
                                </div>
                            </div>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={leerDato} defaultValue={unidades.posicion} name="posicion" id="posicion" type="text" class="validate" />
                                    <label className='active' for="posicion">Posición</label>
                                </div>
                            </div>
                            <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>EDITAR</button>
                            <Link to={`/admin/unidades/${unidad}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                        </form>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarUnidad);
