import CryptoJS from "react-native-crypto-js";
import axios from "axios";

const restAPI = {
  conectar: async (token, data, data_conekta) => {
    var ciphertext = CryptoJS.AES.encrypt(
      "key_kLk5kE7ypVw6jkU1yWrwxg",
      "Y2Fhdg=="
    ).toString();
    let body = {
      auth: ciphertext,
      token: token.id,
      nombre: data.nombre,
      correo: data.correo,
      cel: data.telefono,
      productos: data_conekta.productos,
      cantidades: data_conekta.cantidades,
      precios: data_conekta.precios,
    };
    let url = `https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/conekta`;
    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  upload: async (data) => {
    var ciphertext = CryptoJS.AES.encrypt(
      "key_kLk5kE7ypVw6jkU1yWrwxg",
      "Y2Fhdg=="
    ).toString();
    let formData = new FormData();
    formData.append("auth", ciphertext);
    formData.append("data", data.data);
    formData.append("name", data.name);

    let url =
      "https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/firebase-upload";
    let response = axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return JSON.parse(resp.request.response);
      });

    let json = await response;
    return json;
  },
  uploadCodigo: async (data) => {
    var ciphertext = CryptoJS.AES.encrypt(
      "key_kLk5kE7ypVw6jkU1yWrwxg",
      "Y2Fhdg=="
    ).toString();
    let formData = new FormData();
    formData.append("auth", ciphertext);
    formData.append("data", data.data);
    formData.append("name", data.name);

    // let url = "http://localhost/auth/michel-alvarez/v2/firebase-upload-visita";
    let url =
      "https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/firebase-upload-visita";
    let response = axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return JSON.parse(resp.request.response);
      });

    let json = await response;
    return json;
  },
  uploadGaleria: async (data) => {
    console.log({ data });
    var ciphertext = CryptoJS.AES.encrypt(
      "key_kLk5kE7ypVw6jkU1yWrwxg",
      "Y2Fhdg=="
    ).toString();
    let formData = new FormData();
    formData.append("auth", ciphertext);
    formData.append("data", data.data);
    formData.append("name", data.name);
    formData.append("alto", data.alto);
    formData.append("ancho", data.ancho);

    console.log(data);

    let url =
      "https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/firebase-upload-galeria";
    // let url = 'http://localhost/auth/michel-alvarez/v2/firebase-upload-galeria';
    let response = axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return JSON.parse(resp.request.response);
      });

    let json = await response;
    return json;
  },
};

export default restAPI;
