import React, {Fragment, useEffect, useState}  from 'react';
import Swal from 'sweetalert2';
import CryptoJS from "react-native-crypto-js";
import { firestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

const Login = props => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        user: '',
        password: ''
    })

    useEffect(() => {
        if (sessionStorage.error) {
            if (sessionStorage.error === 'cerrada') {
                Swal.fire(
                    'Completado!',
                    'Sesion cerrada correctamente!',
                    'success'
                )
                sessionStorage.clear();
            } else {
                Swal.fire(
                    'Error!',
                    'Opss, Usted debe iniciar sesion para acceder a la pagina!',
                    'error'
                )
                sessionStorage.clear();
            }
        }
    },[]);

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const iniciarSesion = e => {
        e.preventDefault();

        const sesion = {...state};

        const { firestore } = props;

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("usuario")
                .where("correo", "==", sesion.user)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        resolve(1);
                    } else {
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots    
                            resolve(doc);
                        });
                    }
                })
                .catch((error) => {
                    // console.log("error => ", error);
                    resolve(1);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return acceso;
        }

        guardarState1().then(resultado => {
            if (resultado === 1) {
                Swal.fire(
                    'Error!',
                    'Opss, error al ingresar usuario!',
                    'error'
                )
            } else {
                let bytes  = CryptoJS.AES.decrypt(resultado.data().password, 'Y2Fhdg==');
                let originalText = bytes.toString(CryptoJS.enc.Utf8);
                if (originalText === sesion.password) {
                    Swal.fire(
                        'Bien hecho!',
                        'Bienvenido!',
                        'success'
                    )
                    let tipo = window.btoa(resultado.data().tipo);
                    let tipoMostrar = window.btoa(resultado.data().tipoMostrar);
                    let correo = window.btoa(resultado.data().correo);
                    let nombre =  window.btoa(resultado.data().nombre);

                    sessionStorage.tipo = tipo;
                    sessionStorage.tipoMostrar = tipoMostrar;
                    sessionStorage.correo = correo;
                    sessionStorage.nombre = nombre;
                    sessionStorage.id = resultado.id;

                    navigate('/admin/usuarios');
                } else {
                    Swal.fire(
                        'Error!',
                        'Opss, error al ingresar usuario!',
                        'error'
                    )
                }
            }
        })
    }

    return (
        <Fragment>
            <div style={{display:'flex', alignContent:'center', alignItems:'center', flexDirection:'column', marginTop:'5%'}}>
                <h1 id="font">INICIO DE SESION</h1>
                <div class="row" style={{display:'flex', alignContent:'center', alignItems:'center', borderRadius:'54px', height:'450px', width:'480px', border:'solid 1px #813A76 '}}>
                    <form class="col s12" onSubmit={iniciarSesion}>
                        <div class="input-field" style={{width:'300px', marginLeft:'70px'}}>
                        <i class="fas fa-user prefix"></i>
                        <input id="user" name="user" onChange={leerDato} value={state.user} type="email" class="validate"/>
                        <label for="user">Usuario</label>
                        </div>
                        <div class="input-field" style={{width:'300px', marginLeft:'70px'}}>
                        <i class="fas fa-unlock-alt prefix"></i>
                        <input id="password" name="password" onChange={leerDato} defaultValue={state.password} type="password" class="validate" />
                        <label for="password">Contraseña</label>
                        </div>
                        <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                            <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>Inicia Sesion</button>
                        </div>
                    </form>
                </div>
            </div>
    </Fragment>
    );
};

export default firestoreConnect() (Login);


