import React, { Fragment, Component } from "react";
import M from "materialize-css";

export default class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: "",
      correo: "",
      tipoMostrar: "",
      nombre: "",
    };
  }

  componentDidMount = () => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems);
  };

  componentDidMount = () => {
    if (sessionStorage.getItem("tipo")) {
      var tipo = sessionStorage.getItem("tipo");
      var corre = sessionStorage.getItem("correo");
      var tipoMostrar = sessionStorage.getItem("tipoMostrar");
      var nombre = sessionStorage.getItem("nombre");
      var desbase64nombre = window.atob(nombre, "base64");
      var desbase64tipo = window.atob(tipo, "base64");
      var desbase64correo = window.atob(corre, "base64");
      var desbase64tipoMostrar = window.atob(tipoMostrar, "base64");
      var elems = document.querySelectorAll(".sidenav");
      M.Sidenav.init(elems);
    } else {
      sessionStorage.error = "error";
      window.location.replace("/");
    }
    if (this.state.tipo === "") {
      this.setState({
        tipo: desbase64tipo,
        correo: desbase64correo,
        tipoMostrar: desbase64tipoMostrar,
        nombre: desbase64nombre,
      });
    }
  };

  cerrarSesion = () => {
    sessionStorage.clear();
    sessionStorage.error = "cerrada";
    window.location.replace("/");
  };

  render() {
    return [
      <div style={{ marginBottom: "100px" }}>
        <div>
          <div style={{ backgroundColor: "#813A76", marginBottom: "0px" }}>
            <div style={{ padding: "16px 0" }}>
              <a
                style={{ position: "absolute", top: "30px", right: "30px" }}
                className="col s1"
                data-target="slide-out"
                class="sidenav-trigger"
                href="#!"
              >
                <i class="material-icons" style={{ color: "#ffffff" }}>
                  menu
                </i>
              </a>
              <div
                id="font"
                className="center-align"
                style={{ color: "#ffffff" }}
              >
                <h2 style={{ margin: "0px" }}>{this.props.mensaje}</h2>
              </div>
            </div>
          </div>
        </div>

        <ul id="slide-out" class="sidenav">
          <li>
            <div class="user-view">
              <div class="background">
                <img
                  id="imagenes"
                  alt="Michel Alvarez"
                  src="https://png.pngtree.com/thumb_back/fh260/background/20210207/pngtree-gray-solid-color-simple-background-image_557028.jpg"
                />
              </div>
              <a href="#name">
                <span class="white-text name">{this.state.tipoMostrar}</span>
              </a>
              <a href="#name">
                <span class="white-text name">{this.state.nombre}</span>
              </a>
              <a href="#email">
                <span class="white-text email">{this.state.correo}</span>
              </a>
              <button
                id="font-button"
                class="btn"
                onClick={this.cerrarSesion}
                name="action"
                style={{
                  padding: "0 50px 0 50px",
                  background: "#813A76",
                  marginLeft: "20px",
                }}
              >
                Cerrar Sesion
              </button>
            </div>
          </li>

          {this.state.tipo === "0" ? (
            <span>
              <li>
                <a href="/admin/ventas">
                  <i class="material-icons">attach_money</i>Ganancias
                </a>
              </li>
              <li>
                <a href="/admin/ventas-cursos">
                  <i class="material-icons">monetization_on</i>Ventas Cursos
                </a>
              </li>
              <li>
                <a href="/admin/imagenes-categorias">
                  <i class="material-icons">burst_mode</i>Imagenes
                </a>
              </li>
              <li>
                <a href="/admin/inventario">
                  <i class="material-icons">playlist_add</i>Inventario
                </a>
              </li>
              <li>
                <a href="/admin/categorias">
                  <i class="material-icons">assignment</i>Productos
                </a>
              </li>
              <li>
                <a href="/admin/servicios">
                  <i class="material-icons">room_service</i>Servicios
                </a>
              </li>
              <li>
                <a href="/admin/usuarios">
                  <i class="material-icons">group</i>Usuarios
                </a>
              </li>
              <li>
                <a href="/admin/cursos">
                  <i class="material-icons">play_circle_filled</i>Cursos
                </a>
              </li>
              <li>
                <a href="/admin/envios-costos">
                  <i class="material-icons">local_shipping</i>Costos de Envios
                </a>
              </li>
              <li>
                <a href="/admin/promociones-productos">
                  <i class="material-icons">money_off</i>Promociones prod.
                </a>
              </li>
              <li>
                <a href="/admin/promociones-cursos">
                  <i class="material-icons">money_off</i>Promociones cursos
                </a>
              </li>
              <li>
                <a href="/admin/codigos-de-visita">
                  <i class="material-icons">image</i>Codigos de visita
                </a>
              </li>
              <li>
                <a href="/admin/envios-empaquetar">
                  <i class="material-icons">drafts</i>Por empaquetar
                </a>
              </li>
              <li>
                <a href="/admin/envios-enviar">
                  <i class="material-icons">assignment_late</i>Por enviar
                </a>
              </li>
              <li>
                <a href="/admin/envios-enviados">
                  <i class="material-icons">email</i>Enviados
                </a>
              </li>
            </span>
          ) : (
            <span>
              <li>
                <a href="/admin/ventas">
                  <i class="material-icons">attach_money</i>Ganancias
                </a>
              </li>
              <li>
                <a href="/admin/imagenes-categorias">
                  <i class="material-icons">burst_mode</i>Imagenes
                </a>
              </li>
              <li>
                <a href="/admin/inventario">
                  <i class="material-icons">playlist_add</i>Inventario
                </a>
              </li>
              <li>
                <a href="/admin/categorias">
                  <i class="material-icons">assignment</i>Productos
                </a>
              </li>
            </span>
          )}
        </ul>
      </div>,
    ];
  }
}
