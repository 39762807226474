import React, {Fragment, useState}  from 'react';
import CryptoJS from "react-native-crypto-js";
import { Link, useNavigate } from 'react-router-dom';

import { firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';

import firebaseHelper from '../../../Helpers/Firebase';

const NuevoUsuario = props => {
    const history = useNavigate();
    const [state, setState] = useState({
        correo: '',
        password: '',
        tipoMostrar: '',
        tipo: '',
        nombre: ''
    });

    const agregar = e => {
        e.preventDefault();

        let ciphertext = CryptoJS.AES.encrypt(state.password, 'Y2Fhdg==').toString();
        var tipoMostrar;

        if (state.tipo === '0') {
            tipoMostrar = "Administrador"
        } else if (state.tipo === '1') {
            tipoMostrar = "Otro"
        }
        
        const nuevo = {
            ...state,
            password: ciphertext,
            tipoMostrar: tipoMostrar
        };

        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        firebaseHelper.agregar('usuario', firestore, nuevo, '/admin/usuarios', history);
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="NUEVO USUARIO" />
            <div id="font" className="container">    
                <div className="center" style={{padding: '30px 0 30px 0'}}>
                    <form onSubmit={agregar}>
                        <div style={{margin:'0 15%'}}>
                            <p id="font-text-b" >Tipo Usuario</p>
                            <div style={{display:'flex', justifyContent:'center'}}>    
                                <p style={{marginLeft:'10px'}}>
                                    <label style={{marginLeft:'30px'}}>
                                        <input required onChange={leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                        <span>Administrador</span>
                                    </label>
                                </p>
                                <p>
                                    <label style={{marginLeft:'30px'}}>
                                        <input required onChange={leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                        <span>Otro</span>
                                    </label>
                                </p>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                <label for="nombre">Nombre</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="correo" id="correo" type="email" class="validate" />
                                <label for="correo">Correo</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="password" id="password" type="password" class="validate" />
                                <label for="password">Password</label>
                            </div>
                        </div>
                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR</button>
                        <Link to={'/admin/usuarios'} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (NuevoUsuario);

