import React, {Fragment, useState, useEffect}  from 'react';
import Header from '../../../Components/SideNav';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';


const InventarioEditar = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        cantidad: '',
        categoria: ''
    });

    useFirestoreConnect([
        {
          collection: 'producto',
          doc: id
        }
    ])

    const producto = useSelector(
        ({ firestore: { data } }) => data.producto && data.producto[id]
    )

    useEffect(() => {
        if (producto) {
            setState({
                nombre: producto.nombre,
                cantidad: producto.cantidad,
                categoria: producto.categoria
    
            });
        }
    },[producto]);

    const editar = e => {
        e.preventDefault();

        var producto = {
            nombre: state.nombre,
            cantidad: parseInt(state.cantidad)
        };
        //console.log(producto);
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        firestore.update({
            collection: 'producto',
            doc: id
        }, producto ).then(() => editado());

    }
    
    const editado = () => {
        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Modificacion exitosa',
                    text:'El campo fue modificado exitosamente',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon:'success'
                }
            )
            //redireccion
            history(`/admin/inventario/${state.categoria}`)
            } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje={"MODIFICAR INVENTARIO - " + state.nombre.toUpperCase()} />
            <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                <form onSubmit={editar}>
                    
                    <div style={{margin:'0 15%'}}>
                        <div class="input-field col s12">
                            <input required onChange={leerDato} defaultValue={state.cantidad} name="cantidad" id="cantidad" type="number" class="validate" />
                            <label class="active" for="cantidad">Cantidad en Stock</label>
                        </div>
                    </div>

                    <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>EDITAR</button>
                    <Link to={`/admin/inventario/${state.categoria}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76'}}>REGRESAR</Link>
                </form>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (InventarioEditar);