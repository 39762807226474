import React from 'react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import Router from './Router/Router';

import './index.css';

import { Provider } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore' // <- needed if using firestore
import "firebase/compat/storage";
import "firebase/compat/database" // <- needed if using storage
// import 'firebase/functions' // <- needed if using httpsCallable

import { createStore, combineReducers } from 'redux'
import {
    ReactReduxFirebaseProvider,
    firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const fbConfig = {
    apiKey: "AIzaSyC1pvRK6utX9O0IBRjTK2gQKIkmgNXeoW0",
    authDomain: "michel-alvarez-studio.firebaseapp.com",
    projectId: "michel-alvarez-studio",
    storageBucket: "michel-alvarez-studio.appspot.com",
    messagingSenderId: "173490469615",
    appId: "1:173490469615:web:336752c8d8050fdfd8391a",
    measurementId: "G-59NQKT9E7N"
}

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

function App() {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <Router />
            </ReactReduxFirebaseProvider>
        </Provider>
    )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
