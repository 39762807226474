import Swal from 'sweetalert2';

const firebaseHelper = {
    /**
     * 
     * @param {identificador del dato a eliminar} id 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     */
    eliminar: (id, collection, firestore) => {
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.delete({
                    collection: collection,
                    doc: id
                }).then(() => {
                    Swal.fire({
                        title: 'Eliminado con exito',
                        text:'El campo fue eliminado exitosamente',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon:'success'
                    })
                })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {identificador del dato a eliminar} id 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {conexion con firebase storage} firebase 
     * @param {URL de la imagen a eliminar} urlImagen 
     */
    eliminarImagen: (id, collection, firestore, firebase, urlImagen) => {
        var storage;
        var storageRefEliminar;

        if (urlImagen !== '') {
            storage = firebase.storage();
            storageRefEliminar = storage.refFromURL(urlImagen);
        }
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.delete({
                    collection: collection,
                    doc: id
                }).then(() => {
                    if (urlImagen === '') {
                        Swal.fire({
                            title: 'Eliminación exitosa',
                            text:'El campo fue eliminado exitosamente',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon:'success'
                        })
                    } else {
                        storageRefEliminar.delete().then(() => {
                            Swal.fire({
                                title: 'Eliminación exitosa',
                                text:'El campo fue eliminado exitosamente',
                                confirmButtonColor: '#2DCD22',
                                confirmButtonText: 'Continuar',
                                icon:'success'
                            })
                        }).catch(error => {
                            //console.log("error => ", error);
                            Swal.fire({
                                title: 'Error',
                                text: 'Error al aliminar archivo 1',
                                confirmButtonColor: '#2DCD22',
                                confirmButtonText: 'Continuar',
                                icon: 'error'
                            })
                        });
                    }
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    agregar: (collection, firestore, data, url, history) => {
        firestore.add({ 
            collection: collection 
        }, data).then(() => {
            Swal.fire(
                'Inserción exitosa!',
                'Campo agregado correctamente',
                'success'
            )
            history(url)
        });
    },
    /**
     * 
     * @param {identificador del dato a modificar} id 
     * @param {nombre de la coleccion a editar} collection 
     * @param {conexion con firebase} firestore 
     * @param {datos a editar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    editar: (id, collection, firestore, data, url, history) => {
        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.update({
                    collection: collection,
                    doc: id
                }, data ).then(() => {
                    Swal.fire({
                        title: 'Modificacion exitosa',
                        text:'El campo fue modificado exitosamente',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon:'success'
                    })
                    //redireccion
                    history(url)
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {identificador del dato a buscar} id 
     * @param {nombre de la colleccion a buscar} collection 
     * @param {conexion con firebase} firestore 
     * @returns datos encontrados
     */
     buscarId: async (id, collection, firestore) => {

        function consulta1() {
            return new Promise(async resolve => {
                const cityRef = firestore.collection(collection).doc(id);
                const doc = await cityRef.get();
                if (!doc.exists) {
                    //console.log('No such document!');
                    resolve(1);
                } else {
                    //console.log('Document data:', doc.data());
                    resolve(doc.data());
                }
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return await acceso;
        }
        
        let resultadoFinal = await guardarState1();

        return resultadoFinal;
    },
    /**
     * 
     * @param {identificador del dato a buscar} id 
     * @param {nombre de la colleccion a buscar} collection 
     * @param {conexion con firebase} firestore 
     * @returns datos encontrados
     */
    buscarMultiplesId: async (id, collection, firestore) => {
        function consulta1() {
            return new Promise(async resolve => {
                firestore.collection(collection)
                .where("categoria", "==", id)
                .orderBy("subCategoria")
                .get()
                .then(function(querySnapshot) {
                    //console.log(querySnapshot.docs);
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        resolve(querySnapshot);
                    }
                }).catch(function(error) {
                    //console.log("Error getting document:", error);
                    resolve(1);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return await acceso;
        }
        
        let resultadoFinal = await guardarState1();

        return resultadoFinal;
    },
    /**
     * 
     * @param {conexion con firebase} firestore 
     * @returns numero de venta
     */
    numeroVenta: async (firestore) => {
        function consulta1() {
            return new Promise(async resolve => {
                firestore.collection("ventas")
                .orderBy("fecha")
                .limitToLast(1)
                .get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        resolve(2);
                    } else {
                        snapshot.forEach(doc => {
                            resolve(doc.data());
                        });  
                    }
                })
                .catch(err => {
                    resolve(1);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return await acceso;
        }
        
        let resultadoFinal = await guardarState1();

        return resultadoFinal.numeroCompra;
    },
    /**
     * 
     * @param {conexion con firebase} firestore 
     * @param {fecha con la que se inicia la busqueda} fechaInicial 
     * @param {fecha con la que se finaliza la busqueda} fechaFinal 
     * @returns datos encontrados
     */
    obtenerVentas: async (firestore, fechaInicial, fechaFinal) => {
        let fecha_inicial = new Date(fechaInicial * 1000);
        let fecha_final = new Date(fechaFinal * 1000);
        function consulta1() {
            return new Promise(async resolve => {
                firestore.collection("compras")
                .where("curso", "==", true)
                .where("fecha", ">=", fecha_inicial)
                .where("fecha", "<=", fecha_final)
                .get()
                .then(function(querySnapshot) {
                    //console.log(querySnapshot.docs);
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        resolve(querySnapshot);
                    }
                }).catch(function(error) {
                    // console.log("Error getting document:", error);
                    resolve(1);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return await acceso;
        }
        
        let resultadoFinal = await guardarState1();

        return resultadoFinal;
    }
}

export default firebaseHelper;