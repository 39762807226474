import config from './config';
import CryptoJS from "react-native-crypto-js";

const conektaHelper = {
    initConekta: () => {
        window.Conekta.setPublicKey(config.conekta.staging.publicKey)
    },
    getCardBrand: (cardNumber) => {
        return window.Conekta.card.getBrand(cardNumber)
    },
    validateCardNumber: (cardNumber) => {
        return window.Conekta.card.validateNumber(cardNumber)
    },
    validateCvc: (cvc) => {
        return window.Conekta.card.validateCVC(cvc)
    },
    validateExpirationDate: (vencimiento) => {
        let fecha = vencimiento.split('/');
        return window.Conekta.card.validateExpirationDate(fecha[0], "20" + fecha[1])
    },
    tokenize: async (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
        const tokenParams = {
            card: {
                number: cardNumber,
                name: cardHolder,
                exp_year: expiryYear,
                exp_month: expiryMonth,
                cvc: cvc
            }
        }
        window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
    },
    obtenerVentas: async (fecha_inicial, fecha_final) => {
        let url = "https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/ventas";
        let encoded = window.btoa(config.conekta.staging.privateKey + ':');

        var ciphertext = CryptoJS.AES.encrypt("key_kLk5kE7ypVw6jkU1yWrwxg", 'Y2Fhdg==').toString();
        let body = {
            auth: ciphertext,
            authorization: encoded,
            fecha_inicial: fecha_inicial.toString(),
            fecha_final: fecha_final.toString()
        };
        let request = {
            method:'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        return json;
    },
    obtenerVenta: async (id_order) => {
        let url = "https://www.api-v1.michelalvarezstudio.com/auth/michel-alvarez/v2/venta-id";
        let encoded = window.btoa(config.conekta.staging.privateKey + ':');

        var ciphertext = CryptoJS.AES.encrypt("key_kLk5kE7ypVw6jkU1yWrwxg", 'Y2Fhdg==').toString();
        let body = {
            auth: ciphertext,
            authorization: encoded,
            id: id_order
        };
        let request = {
            method:'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        return json;
    }
}

export default conektaHelper;