import React, {Fragment}  from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const InventarioCategoria = props => {
    return (
        <Fragment>
            <Header mensaje="CATEGORIAS INVENTARIO" />

            <div className="container" style={{padding: '0 0 100px 0'}}>
                {!props.categorias ? (
                    <Loader />
                ) : (
                    props.categorias.length === '0' ? (
                        <h1>Sin categorias</h1>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Categorias</th>
                                    <th>Ver Productos</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {props.categorias.map(categoria => (
                                    <tr key={categoria.id}>
                                        <td>{categoria.nombre}</td>
                                        <td>
                                            <Link style={{marginLeft:'14%'}} to={`/admin/inventario/${categoria.id}`}><i class="material-icons" style={{color:'#813A76'}}>remove_red_eye</i></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
            
        </Fragment>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categorias: ordered.categoria
    }))
)(InventarioCategoria);