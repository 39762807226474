import React, {Fragment, useState}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { useParams, useNavigate, Link } from 'react-router-dom';
import firebaseHelper from '../../../Helpers/Firebase';


const AgregarPregunta = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        pregunta: '',
        respuesta_1: '',
        respuesta_2: '',
        respuesta_3: '',
        respuesta_correcta: '',
        curso: id
    });

    const agregar = e => {
        e.preventDefault();

        //extraer valores del state
        const nuevo = {...state};

        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        firebaseHelper.agregar('preguntas', firestore, nuevo, '/admin/preguntas/' + id, history);

    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Fragment>
            <Header mensaje="NUEVA PREGUNTA" />
            <div id="font" className="container">    
                <div className="center" style={{padding: '30px 0 30px 0'}}>
                    <form onSubmit={agregar}>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="pregunta" id="pregunta" type="text" class="validate" />
                                <label for="pregunta">Pregunta</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="respuesta_1" id="respuesta_1" type="text" class="validate" />
                                <label for="respuesta_1">Respuesta 1</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="respuesta_2" id="respuesta_2" type="text" class="validate" />
                                <label for="respuesta_2">Respuesta 2</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="respuesta_3" id="respuesta_3" type="text" class="validate" />
                                <label for="respuesta_3">Respuesta 3</label>
                            </div>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="respuesta_correcta" id="respuesta_correcta" type="text" class="validate" />
                                <label for="respuesta_correcta">Respuesta Correcta</label>
                            </div>
                        </div>
                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR</button>
                        <Link to={`/admin/preguntas/${id}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (AgregarPregunta);