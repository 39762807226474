import React, {Fragment, useState}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';



const NuevoCurso = props => {
    const history = useNavigate();
    const [state, setState] = useState({
        titulo: ''
    });
    const [descripcion, setDescripcion] = useState("");
    const [requisitos, setRequisitos] = useState("");
    const [descripcionSecundaria, setDescripcionSecundaria] = useState("");

    const agregarCurso = e => {
        e.preventDefault();

        //extraer firestor de props
        const { firestore } = props;

        //extraer valores del state
        const nuevoCurso = {
            ...state,
            descripcion,
            requisitos,
            comentarios: [],
            descripcion_secundaria: descripcionSecundaria
        };

        firestore.add({ collection: 'cursos' }, nuevoCurso).then(() => agregado());
    }
    
    const agregado = () => {
        Swal.fire(
            'Inserción exitosa',
            'Video agregado correctamente',
            'success'
        )
        history('/admin/cursos')
    }

    //extrae los valores del input y los coloca en el state
    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerDescripcion = (e, editor) => {
        setDescripcion(editor.getData());
    }

    const descripcion_secundaria = (e, editor) => {
        setDescripcionSecundaria(editor.getData());

    }

    const leerRequisitos = (e, editor) => {
        setRequisitos(editor.getData());
    }

    return (
        <Fragment>
            <Header mensaje="NUEVO CURSO" />
            <div className="container center-align" style={{marginTop:'2%'}}>

                <form onSubmit={agregarCurso}>  
                    
                    <div class="input-field">
                        <input required onChange={leerDato} name="titulo" id="titulo" type="text" class="validate" />
                        <label for="titulo" id="font-text2">Titulo</label>
                    </div>

                    <div class="input-field">
                        <input required onChange={leerDato} name="titulo_secundario" id="titulo_secundario" type="text" class="validate" />
                        <label for="titulo_secundario" id="font-text2">Titulo Secundario</label>
                    </div>

                    <div class="input-field">
                        <input required onChange={leerDato} name="titulo_exterior" id="titulo_exterior" type="text" class="validate" />
                        <label for="titulo_exterior" id="font-text2">Titulo Exterior</label>
                    </div>

                    <div>
                        <h5>Descripcion</h5>
                        <CKEditor
                            editor={ ClassicEditor }
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={leerDescripcion}
                        />
                    </div>

                    <div>
                        <h5>Descripcion Secundaria</h5>
                        <CKEditor
                            editor={ ClassicEditor }
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={descripcion_secundaria}
                        />
                    </div>

                    <div>
                        <h5>Requisitos</h5>
                        <CKEditor
                            editor={ ClassicEditor }
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={leerRequisitos}
                        />
                    </div>

                    <div class="input-field">
                        <input required onChange={leerDato} name="precio" id="precio" type="text" class="validate" />
                        <label for="precio" id="font-text2">Precio</label>
                    </div>

                    <div>
                        <h5>Incluye</h5>
                        <div class="input-field">
                            <input required onChange={leerDato} name="kit" id="kit" type="text" class="validate" />
                            <label for="kit" id="font-text2">Kit</label>
                        </div>
                        <div class="input-field">
                            <input required onChange={leerDato} name="online" id="online" type="text" class="validate" />
                            <label for="online" id="font-text2">Online</label>
                        </div>
                        <div class="input-field">
                            <input required onChange={leerDato} name="horas" id="horas" type="text" class="validate" />
                            <label for="horas" id="font-text2">Horas</label>
                        </div>
                        <div class="input-field">
                            <input required onChange={leerDato} name="alumnos" id="alumnos" type="text" class="validate" />
                            <label for="alumnos" id="font-text2">Alumnos</label>
                        </div>
                        <div class="input-field">
                            <input required onChange={leerDato} name="certificado" id="certificado" type="text" class="validate" />
                            <label for="certificado" id="font-text2">Certificado</label>
                        </div>
                    </div>
                                
                    <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                        <button id="font" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR</button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (NuevoCurso);