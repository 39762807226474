import React, {Fragment, Component}  from 'react';
import ReactDOM from 'react-dom';
//import { firebaseConnect } from 'react-redux-firebase';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Header from '../../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import restAPI from '../../../../Helpers/RestAPI';
import { Table } from 'react-bootstrap';

//import PropTypes from 'prop-types';

class App extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            file1: '',
            picture: null,
            nombre: '',
            prueba: '',
            galeria: [],
            alto: '',
            ancho: ''
        }
    }

    handelUpload = e => {
        e.preventDefault();

        this.setState({
            file1: e.target.files[0]
        }, () => {
            this.precarga();
        });
    }

    precarga = () => {
        var file = this.state.file1,
        imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        if (this.state.prueba === '') {
            this.setState({
                prueba: '1',
                nombre: this.props.servicios.nombre,
                galeria: this.props.servicios.galeria
            });
        }
    }

    subirDB = async file => {
        const { firestore } = this.props;

        if (file === '1') {
            if ((this.state.file1 === '') || (this.state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                this.preloader();
                var file1 = this.state.file1;
                //console.log('cuntinua');
                const api = await restAPI.uploadGaleria({
                    data: file1,
                    name: this.state.nombre,
                    alto: this.state.alto,
                    ancho: this.state.ancho
                });
                //console.log('------------------');
                console.log(api);
                if (api.success) {
                    var galeria = [...this.state.galeria];
                    galeria.push(api.dataURL);
                    
                    firestore.update({
                        collection: 'servicios',
                        doc: this.props.servicios.id
                    }, { galeria } ).then(() => this.editado());


                } else {
                    Swal.fire({
                        title: 'Error',
                        text: api.type,
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                }
            }
        }
    }

    editado = () => {
        const { history } = this.props;
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        )
        history.go()

    }

    preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    eliminar = (url, position) => {
        //extraer firestor de props
        var { firebase } = this.props;
        const { firestore } = this.props;

        var storage;
        var storageRefEliminar;

        storage = firebase.storage();
        storageRefEliminar = storage.refFromURL(url);
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let galeria = [...this.props.servicios.galeria];
                galeria.splice(position, 1);
                storageRefEliminar.delete().then(() => {
                    //actualizacion en la base de datos
                    firestore.update({
                        collection: "servicios",
                        doc: this.props.servicios.id
                    }, {galeria} ).then(() => {
                        Swal.fire({
                            title: 'Eliminación exitosa',
                            text:'El campo fue eliminado exitosamente',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon:'success'
                        })
                    });
                }).catch(error => {
                    //console.log("error => ", error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al aliminar archivo 1',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        if (!this.props.servicios) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'50px'}}>
                        <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>AGREGAR IMAGEN A GALERIA DE SERVICIOS</h2>
                        <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                            
                        </div>
                        <div className="row">
                            <div className="col s4">    
                                <div className="file-field input-field">
                                    <div className="btn">
                                        <span>File</span>
                                        <input onChange={this.handelUpload} name="file" type="file"/>
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                    </div>
                                </div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="alto" id="alto" type="text" class="validate" />
                                    <label for="alto">Alto</label>
                                </div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="ancho" id="ancho" type="text" class="validate" />
                                    <label for="ancho">Ancho</label>
                                </div>
                                <div className="col s12 btn-outline-light center" style={{padding: '30px 0 30px 0', display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <div id="font" class="btn" onClick={() => this.subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR</div>
                                    <Link to={`/admin/imagenes-categorias`} id="font" class="btn" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#813A76'}}>REGRESAR</Link>
                                </div>
                            </div>
                            <div className="col s8">
                                <img alt="imagen subida" id="imgSalida" width="100%" src=""></img>
                            </div>
                        </div>

                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>Imagen</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {this.props.servicios.galeria.map((dato, index) => (
                                    <tr key={dato.id}>
                                        <td><img src={dato.url} alt="" style={{width:"100px"}} /></td>
                                        <td>
                                            <button style={{border:"none", background:"#fff"}} onClick={() => {
                                                this.eliminar(dato.url, index)
                                            }}>
                                                <i class="material-icons" style={{color:'#813A76 '}}>delete</i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>
                </Fragment>
            );
        }
    }
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'servicios',
            storeAs: 'servicios',
            doc: window.location.pathname.split("/")[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(App);