import React, {Fragment, useState}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const NuevoServicio = props => {
    const history = useNavigate();
    const [state, setState] = useState({
        nombre: '',
        descripcion: '',
        slogan: '',
        preguntas: '',
        opciones: '',
        urlImagen: '',
        urlInterna: '',
        galeria: []
    });

    const agregar = e => {
        e.preventDefault();

        var servicios;
    
        //extraer firestor de props
        const { firestore } = props;

        //guardado en la base de datos
        servicios = {...state}
        
        firestore.add({ collection: 'servicios' }, servicios).then(() => agregado());

    }

    const agregado = () => {
        Swal.fire(
            'Inserción exitosa!',
            'Campo agregado correctamente',
            'success'
        )
        history(`/admin/servicios`)
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const preguntas = (e, editor) => {
        setState({
            ...state,
            preguntas: editor.getData()
        })
    }

    const opciones = (e, editor) => {
        setState({
            ...state,
            opciones: editor.getData()
        })
    }

    const descripcion = (e, editor) => {
        setState({
            ...state,
            descripcion: editor.getData()
        })
    }

    return (
        <Fragment>
            <Header mensaje="NUEVO SERVICIO" />
            <div id="font" className="container">    
                <div className="center" style={{padding: '30px 0 30px 0'}}>
                    <form onSubmit={agregar}>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input required onChange={leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                <label for="nombre">Nombre del Servicio</label>
                            </div>
                        </div>
                        <div style={{margin:'0 15%'}}>
                            <div class="input-field col s12">
                                <input onChange={leerDato} name="slogan" id="slogan" type="text" class="validate" />
                                <label for="slogan">Slogan</label>
                            </div>
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <h5>Descripcion</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={descripcion}
                            />
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <h5>Opciones</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={opciones}
                            />
                        </div>
                        <div style={{margin:'5% 15%'}}>
                            <h5>Preguntas</h5>
                            <CKEditor
                                editor={ ClassicEditor }
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={preguntas}
                            />
                        </div>

                        <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76', marginTop:'25px'}}>AGREGAR</button>
                        <Link to={`/admin/servicios`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76', marginTop:'25px'}}>REGRESAR</Link>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default firestoreConnect()(NuevoServicio);
