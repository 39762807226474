import React, {Fragment, useEffect, useState}  from 'react';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import firebaseHelper from '../../../Helpers/Firebase';
import Loader from '../../../Components/Loader';


const Preguntas = props => {
    const { id } = useParams();
    const [state, setState] = useState("");

    useFirestoreConnect([
        {
          collection: 'preguntas',
          where: ["curso", "==", id]
        }
    ]);

    const pregunta = useSelector(
        ({ firestore: { data } }) => data.preguntas
    );

    useEffect(() => {
        let preguntaArray = [];
        sessionStorage.categoria = id;

        if (pregunta !== undefined) {
            if (pregunta !== null) {
                Object.entries(pregunta).map(preguntaMap => {
                    return preguntaArray.push({
                        ...preguntaMap[1],
                        id: preguntaMap[0]
                    });
                });
            }

            setState(preguntaArray);
        }
    }, [pregunta, id]);

    const eliminar = (id) => {
        const { firestore } = props;

        firebaseHelper.eliminar(id, 'preguntas', firestore);
        
    }

    return (
        <Fragment>
            <Header mensaje="PREGUNTAS" />
            <div className="btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                <Link to={`/admin/nueva-pregunta/${id}`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76'}}>AGREGAR PREGUNTA</Link>
                <Link to={`/admin/cursos`} id="font" class="btn" style={{padding:'0 50px 0 50px', marginLeft:"30px", background:'#813A76'}}>REGRESAR</Link>
            </div>
            <div className="container">
                {state === "" ? (
                    <Loader />
                ) : (
                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                        <thead>
                            <tr>
                                <th>Pregunta</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody id="font">
                            {state.map(pregunta => (
                                <tr key={pregunta.id}>
                                    <td>{pregunta.pregunta}</td>
                                    <td>
                                        <a style={{marginLeft:'14%'}} href={`/admin/editar-pregunta/${pregunta.id}/${id}`}><i class="material-icons" style={{color:'#813A76'}}>create</i></a>
                                        <a href="#!" onClick={ () => eliminar(pregunta.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#813A76'}}>delete</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (Preguntas);
