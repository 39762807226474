import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../../Helpers/Firebase';


class NuevaDescripcion extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            descripcion: '',
            idPage: window.location.pathname.split("/")[3]
        }
    }

    agregar = e => {
        e.preventDefault();
        const { history } = this.props;
        
        let posicion = window.location.pathname.split("/")[4];
        //extraer valores del state
        let copiaUnidad = {...this.props.unidades};
        let copiaVideos = [...this.props.unidades.videos];

        //extraer firestor de props
        const { firestore } = this.props;

        copiaVideos[posicion] = {
            nombre: copiaVideos[posicion].nombre,
            video: copiaVideos[posicion].video,
            descripcion: this.state.descripcion
        };

        copiaUnidad.videos = copiaVideos;
        console.log(copiaUnidad);
        //guardado en la base de datos
        firebaseHelper.editar(this.state.idPage, 'unidades', firestore, copiaUnidad, `/admin/videos-unidad/${this.state.idPage}`, history);

    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVA DESCRIPCION" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="descripcion" id="descripcion" type="text" class="validate" />
                                    <label for="descripcion">Descripcion</label>
                                </div>
                            </div>
                            <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#813A76 '}}>AGREGAR</button>
                            <Link to={`/admin/videos-unidad/${this.state.idPage}`} id="font-button" class="btn" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#813A76 '}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevaDescripcion.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'unidades',
            doc: window.location.pathname.split("/")[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        unidades: ordered.unidades && ordered.unidades[0]
    }))
)(NuevaDescripcion);