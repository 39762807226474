import React, {Fragment, useState, useEffect}  from 'react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import { useParams, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, firestoreConnect } from 'react-redux-firebase';

import restAPI from '../../../Helpers/RestAPI';

import Header from '../../../Components/SideNav';
import Loader from '../../../Components/Loader';


const EditarImagenPresencial = props => {
    const { id } = useParams();
    const history = useNavigate();
    const [state, setState] = useState({
        file1: '',
        uploadValue: 0,
        picture: null,
        curso: '',
        imagenesURL: [],
        galeriaURL: []
    });

    useFirestoreConnect([
        {
          collection: 'cursos',
          doc: id
        }
    ]);

    const curso = useSelector(
        ({ firestore: { data } }) => data.cursos && data.cursos[id]
    );

    useEffect(() => {
        console.log(curso);
        if (curso !== undefined) {
            setState({
                ...state,
                curso: curso
            });
        }
        // eslint-disable-next-line
    }, [curso]);

    const handelUpload = (e, index) => {
        e.preventDefault();
        let copiaCursos = {...state.curso};
        let copiaCursosImagenes = [...state.curso.imagenes];
        let alto, ancho;

        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
            ancho = img.width;
            alto = img.height;
            
            copiaCursosImagenes[index] = {archivo: e.target.files[0], alto, ancho};
            copiaCursos.imagenes = copiaCursosImagenes;
    
            setState({
                ...state,
                curso: copiaCursos
            });

            precarga(index);
        }

    }

    const precarga = (index) => {
        var file = state.curso.imagenes[index].archivo;
        let imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida_" + index).src = result;
        }
        
        reader.readAsDataURL(file);
    }

    const handelUploadGaleria = (e, index) => {
        e.preventDefault();
        let copiaCursos = {...state.curso};
        let copiaCursosGaleria = [...state.curso.galeria];
        let alto, ancho;

        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
            ancho = img.width;
            alto = img.height;

            copiaCursosGaleria[index] = {archivo: e.target.files[0], alto, ancho};
            copiaCursos.galeria = copiaCursosGaleria;
    
            setState({
                ...state,
                curso: copiaCursos
            });

            precargaGaleria(index);
        }

    }

    const precargaGaleria = (index) => {
        var file = state.curso.galeria[index].archivo;
        let imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgGaleria_" + index).src = result;
        }
        
        reader.readAsDataURL(file);
    }

    const subirDB = async file => {
        if (file === '1') {
            Swal.fire({
                title: '¿Esta seguro de actualizar este campo?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#2DCD22',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.value) {
                    preloader();

                    subirImagenes(0);
                } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire({
                        title: 'Cancelado',
                        text: 'El campo sigue intacto :)',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon: 'error'
                    })
                }
            })
        }
    }

    const subirImagenes = async (index) => {
        console.log("subir imagenes " + index);

        let copiaImagenesURL = [...state.imagenesURL];

        let copiaIndex = index;
        
        var nombre = curso.nombre + "_" + index;

        const api = await restAPI.uploadGaleria({
            data: state.curso.imagenes[index].archivo,
            name: nombre,
            alto: state.curso.imagenes[index].alto,
            ancho: state.curso.imagenes[index].ancho
        });
        if (api.success) {
            copiaIndex = copiaIndex + 1;
            copiaImagenesURL.push(api.dataURL);

            setState({
                ...state,
                imagenesURL: copiaImagenesURL
            });

            if (copiaIndex < state.curso.imagenes.length) {
                subirImagenes(copiaIndex);
            } else {
                console.log("termino");
                subirGaleria(0);
            }
            
        } else {
            Swal.fire({
                title: 'Error',
                text: api.type,
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        }
    }

    const subirGaleria = async (index) => {
        console.log("subir galeria " + index);
        let copiaGaleriaURL = [...state.galeriaURL]
        // const { firebase } = props;
        let copiaIndex = index;
        let tamanioGaleria = state.curso.galeria.length;
        // var storage = firebase.storage();
        var nombre = curso.nombre + "_galeria_" + index;

        console.log(state.curso.imagenes[index]);
        const api = await restAPI.uploadGaleria({
            data: state.curso.galeria[index].archivo,
            name: nombre,
            alto: state.curso.imagenes[index].alto,
            ancho: state.curso.imagenes[index].ancho
        });

        console.log(api);
        if (api.success) {
            copiaIndex = copiaIndex + 1;
            copiaGaleriaURL.push(api.dataURL);

            setState({
                galeriaURL: copiaGaleriaURL
            }, () => {
                if (copiaIndex < tamanioGaleria) {
                    subirGaleria(copiaIndex);
                } else {
                    console.log("termino galeria");
                    upload();
                }
            });

        } else {
            Swal.fire({
                title: 'Error',
                text: api.type,
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        }
    }

    const upload = () => {
        const { firestore } = props;

        var datosState = {...state};

        datosState.curso.imagenes = state.imagenesURL;
        datosState.curso.galeria = state.galeriaURL;


        firestore.update({
            collection: 'cursos',
            doc: id
        }, datosState.curso ).then(() => {
            // File deleted successfully
            Swal.fire({
                title: 'Modificacion exitosa',
                text:'El campo fue modificado exitosamente',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon:'success'
            }).then((result) => {
                //redireccion
                history(`/admin/cursos`);
            })
        });
    }

    const preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    return (
        <Fragment>
            <Header mensaje="Editar imagen Curso presencial" />
            <div className="container center-align" style={{marginTop:'50px'}}>
                <h2 id="font" style={{marginBottom:'30px', marginTop:'0'}}>EDITAR IMAGEN A SERVICIOS</h2>
                <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                    
                </div>
                {state.curso === '' ? (
                    <Loader />
                ) : (
                    <div className="row">
                        {curso.imagenes.map((imagen, index) => (
                            <div className="row">
                                <div className="col s4">    
                                    <div className="file-field input-field">
                                        <div className="btn">
                                            <span>File</span>
                                            <input onChange={e => {handelUpload(e, index)}} name="file" type="file"/>
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s8">
                                    <img alt="imagen subida" id={"imgSalida_" + index} width="100%" src={state.curso.imagenes[index].url}></img>
                                </div>
                            </div>
                        ))}

                        <h3>Galeria</h3>
                        {curso.galeria.map((imagen, index) => (
                            <div className="row">
                                <div className="col s4">    
                                    <div className="file-field input-field">
                                        <div className="btn">
                                            <span>File</span>
                                            <input onChange={e => {handelUploadGaleria(e, index)}} name="file" type="file"/>
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s8">
                                    <img alt="imagen subida" id={"imgGaleria_" + index} width="100%" src={state.curso.galeria[index].url}></img>
                                </div>
                            </div>
                        ))}
                        <div className="col s12 btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                            <div id="font" class="btn" onClick={() => subirDB('1')} style={{padding:'0 50px 0 50px', background:'#813A76', margin:"20px"}}>ACTUALIZAR</div>
                            <a href={`/admin/cursos`} id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#813A76', margin:"20px"}}>REGRESAR</a>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default firestoreConnect() (EditarImagenPresencial);